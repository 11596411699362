import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,
  HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {tap, retry, catchError, first, flatMap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { Store, select } from '@ngrx/store';
import {AppState, selectApiState} from '../store/app.states';
import {environment} from '../../environments/environment';
import { DASHBOARDDATA } from '../../assets/data/mockEndpointResponses/dashboardsummary';
import { AGENCYDATA } from '../../assets/data/mockEndpointResponses/agencysummary';
import { ERRORREPORT } from '../../assets/data/mockEndpointResponses/getErrorReportDetailed';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService: ApiService;
  getState: Observable<any>;
  constructor(private injector: Injector, private store: Store<AppState>) {
    this.getState = this.store.select(selectApiState);
  }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // console.log('environment...is onine???:  ' + environment.isOnline);
      return this.getState.pipe(
        first(),
        flatMap(token => {
         // console.log(token.appSettings.isLiveData);
         if (token.appSettings.isLiveData) {
           console.log('allowing api call for live data');
           const re = 'api-token-auth';
           // Exclude interceptor for login request:
           if (request.url.search(re) === -1) {
             this.authService = this.injector.get(ApiService);
             const token: string = this.authService.getToken();
             request = request.clone({
               setHeaders: {
                 'Authorization': 'Token ' + token
                 // 'Content-Type': 'application/json'
               }
             });
           }
           console.log(request);
           return next.handle(request);
         } else {
           let dashEndpoint = 'dashboardsummary';
           let agencyEndpoint = 'agencysummary';
           let errorEndpoint = 'getErrorReportDetailed';
           // offline mode requested
           let offlineData = {};
           if (request.url.search(dashEndpoint) !== -1) {
             offlineData = DASHBOARDDATA;
           } else if (request.url.search(agencyEndpoint) !== -1) {
             offlineData = AGENCYDATA;
           } else if (request.url.search(errorEndpoint) !== -1) {
             offlineData = ERRORREPORT;
           }
           console.log('returning offline data');
           console.log(offlineData);
           // return offlineData;
           return of(new HttpResponse({ status: 200, body: offlineData }));
           // return next.handle(request);
         }
         // return next.handle(request);
        }),
      );
    }
}
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private store: Store<AppState>) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        tap(response => {
          if (response instanceof HttpResponse) {
            console.log(response.status);
            // console.log(response.body.Error);
            if (response.body && response.body.Error && response.body.Error.toString().length > 0) {
              console.log('evt.body.Error');
              console.log(response.body.Error);
              let errorMessage = `Error: ${response.body.Error}`;
              // window.alert(errorMessage);
              // return response;
              return of(new HttpResponse({ status: response.status, body: {Error: errorMessage} }));

            }
          }
        }),
        retry(1),

        catchError((error) => {
          console.log('---Intercepting HttpErrorResponse error---');
          console.log(error);
          if ( error.error.status === 401) {
            console.log('Token interceptor intercepted 401 api error');
            // console.log(response);
          }
          // this.store.dispatch(new LogOut());
          let errorMessage = '';

          if (error.error instanceof ErrorEvent) {

            // client-side error

            errorMessage = `Error: ${error.error.message}`;

          } else {

            // server-side error

            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

          }

          // window.alert(errorMessage);
          return of(new HttpResponse({ status: error.error.status, body: {Error: errorMessage} }));
          // return throwError(errorMessage);

        })
      );
  }
}
