import { Injectable } from '@angular/core';
import {Action, Store} from '@ngrx/store';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Observable, throwError} from 'rxjs';
import { of } from 'rxjs';
import {map, switchMap, tap, catchError, skipWhile, mapTo} from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import {
  AuthActionTypes,
  LogIn, LogInSuccess, FoundCredentials, NotFoundCredentials, LogInFailure,
  SignUp, SignUpSuccess, SignUpFailure,
  LogOut, GetUserData, SetUserData, UpdateUserData , GetUserPermissions
} from '../actions/auth.actions';
import { ApiService } from '../../services/api.service';
import {ApiGetDataType, GetAgencyData, GetDashboardData, HasAgencyData} from '../actions/api_data.actions';
import {AppState} from '../app.states';
import {HttpErrorResponse} from "@angular/common/http";
import { HelpersService } from '../../helpers/helpers.service';
import {Permissions} from "../../data-structure-models/user";


@Injectable()
export class AuthEffects {
  private dashboard_summary_url = 'dashboardsummary?format=json';
  private get_user_url = 'get_my_profile?Content-Type&user_name=';
  private get_user_permissions = 'get_my_permissions?Content-Type&user_name=';
  private permissions = new Permissions();
  private user = {result: {}}
  constructor(
    private actions: Actions,
    private authService: ApiService,
    private router: Router,
    private cookieService: CookieService,
    private store: Store<AppState>,
    private helpersService: HelpersService
  ) {
    this.permissions.dashboard = true;
    this.permissions.user = true;
    this.permissions.admin = false;
    this.permissions.ssr = false;
    this.permissions.agencySummary = false;
    this.permissions.search = false;
    this.permissions.showButtons = false;
  }

  // effects go here

  // if payload contains credentials attempt to login
  // if payload is empty, check if already logged in
  @Effect()
  LogIn: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN),
    map((action: LogIn) => action.payload),
    switchMap(payload => {
      let userPayload;
      if (!payload || !payload.username) {
        console.log('getting cookie effect');
        let token = this.cookieService.get('etpflashtoken');
        let email = this.cookieService.get('username');
        if (token && email) {
          console.log('user info is found, logging in');
          let userGroup = 1;
         // console.log('user group is: ' + userGroup);
          // getUserData
          return of(new FoundCredentials({token, email, userGroup}));
        } else {
          console.log('Could Not Locate User');
          return of(new NotFoundCredentials({error: 'could not locate user'}));
        }
      } else {
        return this.authService.logIn(payload.username, payload.password).pipe(
          map((user) => {
              // console.log(user);
              if (user && user.token && !user.Error) {
                let userGroup = 1;
                // etuserdata
                return new LogInSuccess({token: user.token, email: payload.username, userGroup});
              } else {
                return new LogInFailure({error: 'could not locate user'});
              }
            }, catchError((error: HttpErrorResponse) => throwError(error))
          )).pipe(
          catchError((error) => {
            return of(new LogInFailure({error}));
          }));
      }
    }));
  // if credentials are not found, do not throw error, route guard will direct to login page
  @Effect({dispatch: false})
  NotFoundCredentials: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.NOT_FOUND_CREDENTIALS));

  // if credentials are found, allow page to continue loading. Also pre-load API calls.
  @Effect({dispatch: false})
  FoundCredentials: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.FOUND_CREDENTIALS),
    tap((user) => {
      // console.log(user);
      let userPayload = {
        url: this.get_user_url + user.payload.email.trim().toLowerCase()
      };
      this.store.dispatch(new GetUserData(userPayload));
      let userPermPayload = {
        email: user.payload.email.trim().toLowerCase()
      };
      this.store.dispatch(new GetUserPermissions(userPermPayload));

      const payload = {
        url: this.dashboard_summary_url
      };
      this.store.dispatch(new GetDashboardData(payload));
    })
  );

  // If login is successful we need to route from /login to /dashboard
  @Effect({dispatch: false})
  LogInSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((user) => {
      // localStorage.setItem('token', user.payload.token);
      let expiredDate = new Date();
      expiredDate.setDate(expiredDate.getDate() + 30);
      // console.log('SETTING TOKEN: ' + user.payload.token);
      this.cookieService.set('etpflashtoken', user.payload.token, expiredDate);
      this.cookieService.set('username', user.payload.email, expiredDate);
      console.log('getting dashboard data...');
      const dashPayload = {
        url: this.dashboard_summary_url
      };
      this.store.dispatch(new GetDashboardData(dashPayload));


      let userPermPayload = {
        email: user.payload.email.trim().toLowerCase()
      };
      this.store.dispatch(new GetUserPermissions(userPermPayload));
      let userPayload = {
        url: this.get_user_url + user.payload.email.trim().toLowerCase()
      };
      this.store.dispatch(new GetUserData(userPayload));
      this.router.navigateByUrl('/dashboard');
    })
  );

  // if login failed, throw error message to data store, which gets picked up by login component to display
  @Effect({dispatch: false})
  LogInFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE)
  );

  // TODO: not tested or yet available, awaiting requirements
  @Effect()
  SignUp: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.SIGNUP),
    map((action: SignUp) => action.payload),
    switchMap(payload => {
      return this.authService.signUp(payload.email, payload.password).pipe(
        map((user) => {
          return new SignUpSuccess({token: user.token, email: payload.email});
        })).pipe(
        catchError((error) => {
          return of(new SignUpFailure({error}));
        }));
    }));

  // TODO: not tested or yet available, awaiting requirements
  @Effect({dispatch: false})
  SignUpSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.SIGNUP_SUCCESS),
    tap((user) => {
      // localStorage.setItem('token', user.payload.token);
      this.cookieService.set('etpflashtoken', user.payload.token);
      this.cookieService.set('username', user.payload.email);
      this.router.navigateByUrl('/dashboard');
    })
  );

  // TODO: not tested or yet available, awaiting requirements
  @Effect({dispatch: false})
  SignUpFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.SIGNUP_FAILURE)
  );

  // remove cookies and logout.
  @Effect({dispatch: false})
  public LogOut: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGOUT),
    tap((user) => {
      // localStorage.removeItem('token');
      this.cookieService.delete('etpflashtoken');
      this.cookieService.delete('username');
      this.router.navigateByUrl('/login');
    })
  );
  // @Effect()
  @Effect()
  GetUserData: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.GET_USER_DATA),
    map((action: GetUserData) => action.payload),
    switchMap(payload => {
      return this.authService.getData(payload.url).pipe(
        map( (response) => {
          // console.log('response in effect is:');
          // console.log(response);
          if (response && response.result && (!response.result.avatar || response.result.avatar.length === 0)) {
            let userCookie = this.helpersService.getUserInfoCookie();
            if (userCookie && userCookie.avatar && userCookie.avatar.length > 0) {
              response.result.avatar = userCookie.avatar;
            } else {
              response.result.avatar = 'https://source.unsplash.com/j5MCxwaP0R0/100x100';
            }
          } else {
            if (!response.result) {
              response.result = {avatar : 'https://source.unsplash.com/j5MCxwaP0R0/100x100'};
            }
          }
          /*// get_user_permissions
          console.log('Getting user permissions!');
          console.log(this.get_user_permissions + response.result.email);
          /////
          this.authService.getData(this.get_user_permissions + response.result.email).pipe(
            map( (result) => {
            if (result) {
              console.log('got PERMMMMMMMM');
              console.log(result);
              let permissions = new Permissions();
              permissions.dashboard = true;
              permissions.user = true;
              permissions.admin = false;
              permissions.ssr = false;
              permissions.agencySummary = false;
              permissions.search = false;
              result.result.forEach(permission => {
                              // "SystemLogsReadGrp","SSRReportReadGrp", "ElasticSearchGrp", "LogsReadGrp"
                              if (permission.name.toLowerCase() === 'systemlogsreadgrp') {
                                permissions.admin = true;
                              } else if (permission.name.toLowerCase() === 'ssrreportreadgrp') {
                                permissions.ssr = true;
                              } else if (permission.name.toLowerCase() === 'elasticsearchgrp') {
                                permissions.search = true;
                              } else if (permission.name.toLowerCase() === 'logsreadgrp') {
                                permissions.agencySummary = true;
                              }
                            });
              response.result.permissions = permissions;
              this.store.dispatch(new UpdateUserData(payload));
            } else {
              console.log('PROBLEM');
            }
          }));*/
          return new SetUserData(response);
          // response.result.permissions = new Permissions();
          /////
        })).pipe(
        catchError((error) => {
          // return of(new LogInFailure({ error }));
          console.log('GetUserData error');
          return null;
        }));
    }));
  // @Effect()
  @Effect()
  GetUserPermissions: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.GET_USER_PERMISSIONS),
    map((action: GetUserPermissions) => action.payload),
    switchMap(payload => {
      return this.authService.getData(this.get_user_permissions + payload.email).pipe(
        map( (result) => {
          // get_user_permissions
          console.log('Getting user permissions!');
          // console.log(this.get_user_permissions + payload.email);
          if (result) {
                result.result.forEach(permission => {
                  // "SystemLogsReadGrp","SSRReportReadGrp", "ElasticSearchGrp", "LogsReadGrp"
                  if (permission.name.toLowerCase() === 'systemlogsreadgrp') {
                    this.permissions.admin = true;
                  } else if (permission.name.toLowerCase() === 'ssrreportreadgrp') {
                    this.permissions.ssr = true;
                  } else if (permission.name.toLowerCase() === 'elasticsearchgrp') {
                    this.permissions.search = true;
                  } else if (permission.name.toLowerCase() === 'logsreadgrp') {
                    this.permissions.agencySummary = true;
                  }
                });
                result.result.permissions = this.permissions;
                this.user.result = this.permissions;
                this.store.dispatch(new UpdateUserData(this.user));
              } else {
                console.log('PROBLEM');
                this.user.result = this.permissions;
                this.store.dispatch(new UpdateUserData(this.user));
          }
          return new SetUserData(result);
          // response.result.permissions = new Permissions();
          /////
        })).pipe(
        catchError((error) => {
          // return of(new LogInFailure({ error }));
          console.log('Permissions error');
          //
          this.user.result = {permissions: this.permissions};
          this.store.dispatch(new UpdateUserData(this.user));
          return null;
        }));
    }));
  @Effect({dispatch: false})
  UpdateUserData: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.SET_USER_DATA),
    tap((user) => {
      const payload = user;
      return new SetUserData(payload);
    })
  );
}
