import { createFeatureSelector } from '@ngrx/store';

import * as auth from './reducers/auth.reducers';
import * as api from './reducers/api.reducers';


export interface AppState {
  authState: auth.State;
  apiState: api.State;
}

export const reducers = {
  auth: auth.reducer,
  api: api.reducer
};

export const selectAuthState = createFeatureSelector<AppState>('auth');
export const selectApiState = createFeatureSelector<AppState>('api');
