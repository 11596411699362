import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LogOut} from '../../store/actions/auth.actions';
import { Observable } from 'rxjs';
import { AppState, selectAuthState } from '../../store/app.states';
import { Store } from '@ngrx/store';
import {Router} from '@angular/router';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
  getState: Observable<any>;
  username = null;
  avatar = '';
  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {
    this.getState = this.store.select(selectAuthState);
  }
  ngOnInit() {
    // this.username = this.cookieService.get('username');
    this.getState.subscribe((state) => {
      if (state.user && state.user.email) {
        this.username = state.user.email;
        this.avatar = state.user.avatar;
      }
    });
    }
  logout() {
    console.log('logout from topbar');
    this.store.dispatch(new LogOut());
  }
  openSearchBar(thisObj) {
    $('.search-bar-triangle').toggle();
    $('#searchBar').css('display', (i, prop) => {
      return prop === 'flex' ? 'none' : 'flex';
    });
    $('#searchBar input').focus();
  }

  onSearch() {
    $('h1.page-header').text('Search results');
    $('h1.page-header').css('margin-bottom','0px');
    $('.search-results-count').text('About 231,287 results');
  }
}

