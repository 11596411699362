import {Component, OnInit, OnChanges, SimpleChanges, Input} from '@angular/core';
import {Router, Event, NavigationStart} from '@angular/router';
import {Observable} from 'rxjs';
import {AppState, selectAuthState} from '../../store/app.states';
import { Store } from '@ngrx/store';
import {Permissions} from "../../data-structure-models/user";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['../../../assets/css/sb-admin-2.css', './sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnChanges {
  @Input() url: string;
  getState: Observable<any>;
  authLevel: 0;
  permissions: Permissions;
  isExpanded: boolean = false;
  activeMenuSelection = 'dahboard';

  constructor(private router: Router, private store: Store<AppState>) {
    this.getState = this.store.select(selectAuthState);
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator //
        if (event.url.toLowerCase().indexOf('dashboard') === -1 &&
          event.url.toLowerCase().indexOf('ssr_reports') === -1 &&
          event.url.toLowerCase().indexOf('keyword_search') === -1 &&
          event.url.toLowerCase().indexOf('agency_summary') === -1 &&
          event.url.toLowerCase().indexOf('diagnostic_panel') === -1) {
          // navigated to non side menu page, clear selected css
          this.activeMenuSelection = '';
        }
      }
    });
  }
  ngOnInit() {
    this.setSelectedMenu(this.router.url);
    this.getState.subscribe((state) => {
      if (state.user && state.user.userLevel) {
        this.authLevel = state.user.userLevel;
      }
      if (state && state.user && state.user.permissions) {
        this.permissions = state.user.permissions;
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.setSelectedMenu(this.router.url);
  }
  sidePanelActivate() {
    this.isExpanded = !this.isExpanded;
    let isExpanded = "false";
    if (this.isExpanded)
    {
      isExpanded = "false"
    } else {
      isExpanded = "true"
    }
    if (this.isExpanded) {
      document.getElementById("sidebarToggleOpen").setAttribute("aria-expanded", isExpanded);

    } else {
      document.getElementById("sidebarToggleClose").setAttribute("aria-expanded", isExpanded);

    }

    /*$('button#sidebarToggle').attr('aria-expanded', function(_, attr) {
       return !(attr == 'true')
     });*/
    $('nav#nav-components').toggleClass('aside-collapsed');
   // $('ul#nav-sidebar').toggleClass('nav-icons-override');
    // $('ul#nav-sidebar').toggleClass('customSize');

    // $('nav#nav-size_override').toggleClass('override-width-min');
    $('.flex-content').toggleClass('flex-content-expanded');
    // toggleSidebar();
  }
  menuItemClicked(itemName) {
    this.activeMenuSelection = itemName;
  }
  setSelectedMenu(url) {
    if (url.includes('dashboard')) { this.activeMenuSelection = 'dashboard'; }
    else if (url.includes('ssr_reports')) { this.activeMenuSelection = 'ssr_reports'; }
    else if (url.includes('diagnostic_panel')) { this.activeMenuSelection = 'diagnostic_panel'; }
    else if (url.includes('keyword_search')) { this.activeMenuSelection = 'keyword_search'; }
    else if (url.includes('agency_summary')) { this.activeMenuSelection = 'agency_summary'; }
  }
}
