import { Component, OnDestroy, OnInit, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '../../services/api.service';

//data modals
import { DashboardData } from './dashboard-data';
import { DASHBOARDDATA, DASHBOARDDATAEMPTY } from '../../../assets/data/mockEndpointResponses/dashboardsummary';

// Store Imports
import {GetDashboardData} from '../../store/actions/api_data.actions';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {AppState, selectApiState, selectAuthState} from '../../store/app.states';
import {Permissions} from '../../data-structure-models/user';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnDestroy, OnInit {

	private dashboard_summary_url = 'dashboardsummary?format=json';
	public response: DashboardData;
	private offlineErrorMsg = '';
	private defaultDashboardData;
  private dashboardSubscription;
  private authSubscription;
  public chartReady;

	public doughnutChartLabels;
	public doughnutChartData;
	public doughnutChartType = 'doughnut';
	
	public lineChartData : ChartDataSets[];
	public lineChartLabels : Label[];
	public lineChartType = 'line';
	public lineChartLegend = true;
	public lineChartPlugins = [pluginAnnotations];

	public barChartLabels: Label[];
	public barChartType: ChartType = 'bar';
  public barChartLegend = true;
	public barChartPlugins = [pluginDataLabels];
	public barChartData: ChartDataSets[];
  public permissions = new Permissions();
  public initialized = false;
  private donutColors = [
    {
      backgroundColor: [
        '#2e6073',
        '#991f3d',
        '#e6dadd',
        '#99bfcc',
        '#ced4d9'
      ]
    }
  ];

  private primReqColors = [
    {
      backgroundColor: [
        '#2e6073',
        '#991f3d',
        '#e6dadd',
        '#99bfcc',
        '#ced4d9'
      ]
    }
  ];

  getState: Observable<any>;
  getAuthState: Observable<any>;
  usingLiveData = true;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

	constructor(private apiService: ApiService,
				private httpClient: HttpClient,
				private router: Router,
        private store: Store<AppState>) {
    this.response = DASHBOARDDATAEMPTY;
    this.doughnutChartLabels = this.response['agencybreakdown']['labels'],
      this.doughnutChartData = this.response['agencybreakdown']['values'],

      this.lineChartData = [{data: this.scale_data(this.response['solicitationovertime']['values'],.0000001), label: "Solicitation"}],
      this.lineChartLabels = this.response['solicitationovertime']['labels'],

      this.barChartLabels = this.response['primaryrequirement']['labels'],
      this.barChartData = [{data: this.response['primaryrequirement']['values'], label: "Requirements"}],
      this.chartReady = true;
    this.getState = this.store.select(selectApiState);
    this.getAuthState = this.store.select(selectAuthState);
  }
	
	scale_data(data, scale){
		//scale data by scale parameter
		var newArr = [];
    
		for(var i = 0; i < data.length; i++) {
			newArr[i] = data[i] * scale;
		}
		return newArr;
	};


	ngOnInit() {
    this.authSubscription = this.getAuthState.subscribe((state) => {
      if (state && state.user && state.user.permissions) {
        this.initialized = true;
        this.permissions = state.user.permissions;
      }
    });
		this.dashboardSubscription = this.getState.subscribe((state) => {
      // Check if this API call is in Store memory
      if (state.dashboard_api_requests && state.dashboard_api_requests.length > 0) {
        let date;
        let successDate;
        // iterate over each saved response
        for (let i = state.dashboard_api_requests.length - 1; i >= 0; i--) {
          // iterated from most recent respponses and look for matching
          if (state.dashboard_api_requests[i].url === this.dashboard_summary_url) {
            // if most recent request for this call was error, continue looking for valid data to use,
            // but make error note to user (do not break loop)
            if (state.dashboard_api_requests[i].response.Error) {
              if (!date) {
                // set date of most recent error so we know if we should retry req. below
                date = state.dashboard_api_requests[i].date;
              }
              this.usingLiveData = false;
              this.offlineErrorMsg = 'Warning: Page encountered error getting data! Please try again later.';
            } else {
              this.response = state.dashboard_api_requests[i].response;
              successDate = state.dashboard_api_requests[i].date;
              if (this.response && this.response['_source'] && this.response['_source'].indexOf('cached') !== -1) {
                this.usingLiveData = false;
                this.offlineErrorMsg = 'Warning: Site is using cached data in offline mode, some features may be unavailable!';
              } else {
                if (date) {
                  this.usingLiveData = false;
                  this.offlineErrorMsg = 'Warning: Page is using cached data, API service may be temporarily unavailable. ' +
                    'Please try again later.';
                } else {
                  this.usingLiveData = true;
                }
              }
              this.doughnutChartLabels = this.response['agencybreakdown']['labels'],
                this.doughnutChartData = this.response['agencybreakdown']['values'],

                this.lineChartData = [{
                  data: this.scale_data(this.response['solicitationovertime']['values'], .0000001),
                  label: "Solicitation"
                }],
                this.lineChartLabels = this.response['solicitationovertime']['labels'],

                this.barChartLabels = this.response['primaryrequirement']['labels'],
                this.barChartData = [{data: this.response['primaryrequirement']['values'], label: "Requirements"}];
              this.chartReady = true;
              const ONE_HOUR = 60000 * 60;
              if (Date.now() - successDate >= ONE_HOUR) {
                console.log('Dashboard fetching more up to date data');
                this.callDashboardApi();
              }
              break;
            }
            const ONE_MINUTE = 60000;
            if (!date || ((Date.now() - date) >= ONE_MINUTE )) {
              console.log('dashboad looking for data');
              this.callDashboardApi();
            }
          }
        }
      } else {
        this.response = DASHBOARDDATA;
        console.log('dashboad looking for data');
        this.callDashboardApi();
      }
    });
	}


	callDashboardApi() {
	  // Dashboard API is now called from the ngrx store LogInSuccess effect--
    // This is in attempt to get fastest load time possible
      const payload = {
        url: this.dashboard_summary_url
      };
      this.store.dispatch(new GetDashboardData(payload));
  }

	ngOnDestroy() {
    this.dashboardSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
  }

	public lineChartColors: Color[] = [
		{
      backgroundColor: 'rgba(153,31,61, .1)',
      borderColor: '#660a21',
			pointHoverBackgroundColor: '#991f3d',
			pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      pointRadius: 5,
      pointHoverRadius: 11,
      pointBackgroundColor: '#fff',
      pointBorderColor: '#991f3d',
      pointBorderWidth: 2
    },
		{ // grey
		  backgroundColor: 'rgba(148,159,177,0.2)',
		  borderColor: 'rgba(148,159,177,1)',
		  pointBackgroundColor: 'rgba(148,159,177,1)',
		  pointBorderColor: '#fff',
		  pointHoverBackgroundColor: '#fff',
		  pointHoverBorderColor: 'rgba(148,159,177,0.8)'
		},
		{ // dark grey
		  backgroundColor: 'rgba(77,83,96,0.2)',
		  borderColor: 'rgba(77,83,96,1)',
		  pointBackgroundColor: 'rgba(77,83,96,1)',
		  pointBorderColor: '#fff',
		  pointHoverBackgroundColor: '#fff',
		  pointHoverBorderColor: 'rgba(77,83,96,1)'
		}
	  ];

	  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
		console.log(event, active);
	  };

	  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
		console.log(event, active);
	  };

	  public lineChartOptions: (ChartOptions & { annotation: any }) = {
		responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
    },
		scales: {
		  // We use this empty structure as a placeholder for dynamic theming.
		  xAxes: [ {
			ticks: {
			  min: 2015,
			  max: 2029,
			}
		  }],
		  yAxes: [
			{
			  id: 'y-axis-0',
			  position: 'left',
			  scaleLabel: {
				display: true,
				labelString: 'US Dollars (millions)'
			  },
			  gridLines: {
				color: 'rgba(229 ,115 ,143,0.3)',
			  },
			  ticks: {
				fontColor: 'black',
			  }
			}
		  ]
		},
    animation: {
      duration: 500,
    },
		annotation: {
		}
	  };

	  public barChartOptions: ChartOptions = {
		responsive: true,
		// We use these empty structures as placeholders for dynamic theming.
		scales: { xAxes: [{}], yAxes: [{}] },
		plugins: {
		  datalabels: {
			anchor: 'end',
			align: 'end',
		  }
		},
      animation: {
        duration: 500,
      }
	  };

    public donutOptions = {
    cutoutPercentage: 82,
      plugins: {
        datalabels: {
          display: false,
        },
      },
      animation: {
        duration: 500,
      }
  };
}
