import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  constructor(
    private cookieService: CookieService
  ) { }
  constructTable2(dataArray) {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(dataArray[0]);
    let csv = dataArray.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    csv = csv.join('\r\n');
    return csv;
  }
  downloadCsv(dataArray, fileName) {
    const csvContent = this.constructTable2(dataArray);
    const downloadLink = document.createElement('a');
    const blob = new Blob([csvContent], {type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For IE
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      // Not IE
      downloadLink.href = url;
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }
  formatDate() {
    let d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }
  setUserInfoCookie(data) {
    localStorage.setItem('flashSettings', JSON.stringify(data));
  }
  getUserInfoCookie() {
    let cookie = localStorage.getItem('flashSettings');
    let returnCookie = {
      avatar: ''
    };
    if (cookie) {
      returnCookie = JSON.parse(cookie);
    }
    return returnCookie;
  }
}
