import { ApiGetDataType, All } from '../actions/api_data.actions';
import {DashboardData} from '../../internal/dashboard/dashboard-data';
import { AgencyDetails } from '../../internal/agency-summary/agency-details';
import {SearchResultsDataModel} from '../../data-structure-models/data-models';
import { AppSettings } from '../../data-structure-models/appSettings';

export interface State {
  dashboard_api_requests: Array<{url: string, response: DashboardData, date: Date}> | null;
  agencysummary_api_requests: Array<{url: string, response: AgencyDetails, date: Date}> | null;
  keywordsearch_api_requests: Array<{url: string, response: SearchResultsDataModel, date: Date}> | null;
  error_log: Array<{time: Date, issue: string, location: string }> | null;
  appSettings: AppSettings | null;
}
export const initialState: State = {
  dashboard_api_requests: [],
  agencysummary_api_requests: [],
  keywordsearch_api_requests: [],
  error_log: [],
  appSettings: {
    isDarkMode: false,
    isLiveData: true
  }
};
export function reducer(state = initialState, action: All): State {
  switch (action.type) {
    case ApiGetDataType.HAS_DASHBOARD_SUMMARY: {
      // console.log('completed Dashboard Data');
      const date = Date.now();
      action.payload.date = date;
      return {
        ...state,
        dashboard_api_requests: [...state.dashboard_api_requests, action.payload]
      };
    }
    case ApiGetDataType.HAS_AGENCY_SUMMARY: {
      console.log('completed Agency Data');
      const date = Date.now();
      action.payload.date = date;
      return {
        ...state,
        agencysummary_api_requests: [...state.agencysummary_api_requests, action.payload]
      };
    }
    case ApiGetDataType.HAS_KEYWORD_SEARCH: {
      console.log('completed Search Data');
      const date = Date.now();
      action.payload.date = date;
      return {
        ...state,
        keywordsearch_api_requests: [...state.keywordsearch_api_requests, action.payload]
      };
    }
    case ApiGetDataType.UPDATE_ERROR_LOG: {
      const date = Date.now();
      action.payload.date = date;
      return {
        ...state,
        error_log: [...state.error_log, action.payload]
      };
    }
    case ApiGetDataType.UPDATE_APP_STATE: {
      state.keywordsearch_api_requests = [];
      state.agencysummary_api_requests = [];
      state.dashboard_api_requests = [];
      // let reset = initialState;
      // reset.appSettings.isLiveData = action.payload;
      // return reset;

      return {
        ...state,
        appSettings: {...action.payload},
      };
    }
    default: {
      return state;
    }
  }
}
