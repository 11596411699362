import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { ApiService } from '../../services/api.service';
import { HelpersService } from '../../helpers/helpers.service';

// data modals
import { AgencyDetails } from './agency-details';

// Store Imports
import { Store, select } from '@ngrx/store';
import { Observable, interval, Subscription } from 'rxjs';      // Added interval and Subscription for auto refresh
import {AppState, selectApiState, selectAuthState} from '../../store/app.states';
import {GetAgencyData} from '../../store/actions/api_data.actions';
import { AGENCYDATA, AGENCYDATA_EMPTY } from '../../../assets/data/mockEndpointResponses/agencysummary';
import {Permissions} from '../../data-structure-models/user';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-agency-summary',
  templateUrl: './agency-summary.component.html',
  styleUrls: [ './agency-summary.component.css']
})

export class AgencySummaryComponent implements OnDestroy, OnInit {

  constructor(private apiService: ApiService, private helpersService: HelpersService, private store: Store<AppState>) {
    this.getState = this.store.select(selectApiState);
    this.getAuthState = this.store.select(selectAuthState);
    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 10,
      ordering: true,
      searching: true,
      destroy: true,
      order: [[10, 'desc'], [3, 'desc']]
    };
  }
  private agencySubscription;
  private usingLiveData = true;
  private offlineErrorMsg = '';
  private agency_summary_url = 'agencysummary?format=json';
  public response: AgencyDetails[];
  private unprocessed_response: AgencyDetails;
  private updateSubscription: Subscription;                 // Auto refresh Subscription

  getState: Observable<any>;
  getAuthState: Observable<any>;
  public permissions = new Permissions();
  public initialized = false;
  private authSubscription;
  // agency_details: AgencyDetails[] = [];
  dtOptions: DataTables.Settings = {};
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<AgencyDetails> = new Subject();

  private ngUnsubscribe: Subject<AgencyDetails> = new Subject();

  ngOnInit(): void {
    this.authSubscription = this.getAuthState.subscribe((state) => {
      if (state && state.user && state.user.permissions) {
        this.initialized = true;
        this.permissions = state.user.permissions;
      }
    });
    this.agencySubscription = this.getState.subscribe((state) => {
      // I have no idea why this setTimout function works at 0, but if this is removed, the datatable buttons are removed when
      // reloading cached api response.
      setTimeout(() => {
        // Check if this API call is in Store memory
        if (state.agencysummary_api_requests && state.agencysummary_api_requests.length > 0) {
          let date;
          let successDate;
          // iterate over each saved response
          for (let i = state.agencysummary_api_requests.length - 1; i >= 0; i--) {
            // iterated from most recent responses and look for matching
            if (state.agencysummary_api_requests[i].url === this.agency_summary_url) {
              // if most recent request for this call was error, continue looking for valid data to use,
              // but make error note to user (do not break loop)
              if (state.agencysummary_api_requests[i].response.Error) {
                if (!date) {
                  // set date of most recent error so we know if we should retry req. below
                  date = state.agencysummary_api_requests[i].date;
                }
                this.usingLiveData = false;
                this.offlineErrorMsg = 'Warning: Page encountered error getting data! Please try again later.';
              } else {
                this.unprocessed_response = state.agencysummary_api_requests[i].response;
                successDate = state.agencysummary_api_requests[i].date;
                // if in offline mode
                if (this.unprocessed_response && this.unprocessed_response[0]._source && this.unprocessed_response[0]._source.indexOf('cached') !== -1) {
                  this.usingLiveData = false;
                  console.log('set usingLiveData false');
                  this.offlineErrorMsg = 'Warning: Site is using cached data in offline mode, some features may be unavailable!';
                } else {
                  if (date) {
                    // if last api response for endpoint was error
                    this.usingLiveData = false;
                    this.offlineErrorMsg = 'Warning: Page is using cached data, API service may be temporarily unavailable. ' +
                      'Please try again later.';
                  } else {
                    // else good-- live date
                    this.usingLiveData = true;
                  }
                }
                const ONE_HOUR = 60000 * 60;
                if (Date.now() - successDate >= ONE_HOUR) {
                  console.log('Dashboard fetching more up to date data');
                  this.callAgencyApi();
                }
                // console.log(this.unprocessed_response);
                this.response = this.processBooleans(this.unprocessed_response);
                // Calling the DT trigger to manually render the table
                this.dtTrigger.next();
                break;
              }
              const ONE_MINUTE = 60000;
              if (!date || ((Date.now() - date) >= ONE_MINUTE)) {
                console.log('dashboad looking for data');
                this.callAgencyApi();
              }
            }
          }
        } else {
          // this.response = this.processBooleans(AGENCYDATA_EMPTY);
          console.log('dashboad looking for data');
          this.callAgencyApi();
          // this.response = this.processBooleans(this.response);
          // Calling the DT trigger to manually render the table
          // this.dtTrigger.next();
        }
      }, 0);
    });

    // only Proceed if we are waiting on API data
    /*      if (!this.response) {
            // I have no idea why this setTimout function works at 0, but if this is removed, the datatable buttons are removed when
            // reloading cached api response.
            setTimeout(() => {
              if (!this.response && state.agencysummary_api_requests && state.agencysummary_api_requests[0]) {
                if (state.agencysummary_api_requests.find(x => x.url === this.agency_summary_url).response.Error) {
                  this.usingLiveData = false;
                } else {
                  this.response = state.agencysummary_api_requests.find(x => x.url === this.agency_summary_url).response;
                  if (this.response && this.response[0]['_source'] && this.response[0]['_source'].indexOf('cached') !== -1) {
                    this.usingLiveData = false;
                  } else {
                    this.usingLiveData = true;
                  }
                }
              }
              if (!this.response) {
                this.callAgencyApi();
              } else {
                this.response = this.processBooleans(this.response);
                // Calling the DT trigger to manually render the table
                this.dtTrigger.next();
              }
            }, 0);
          }
        });*/

    // Updates page subscription every 10 minutes refreshing the data from the API
    this.updateSubscription = interval(600000).subscribe((val) => {this.callAgencyApi(); });

  }
  callAgencyApi() {
    if (!this.unprocessed_response) {
      const payload = {
        url: this.agency_summary_url
      };
      this.store.dispatch(new GetAgencyData(payload));
    }
  }
  onDownload() {
    this.helpersService.downloadCsv(this.response, 'agency_summary.csv');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.agencySubscription.unsubscribe();
    this.authSubscription.unsubscribe();
  }

  processBooleans(dataArray) {
    const newDataArray = new Array <AgencyDetails>();
    // new Array<GenderType>();

    dataArray.forEach(function(entry, index, object) {
      if (entry.RecordcountRS === 0) {
        entry.ssrReady = 'No';
      } else {
        entry.ssrReady = 'Yes';
      }

      if (entry.Filedownloadratio === 0) {
        entry.keywordSearchReady = 'No';
      } else {
        entry.keywordSearchReady = 'Yes';
      }
      // filter
      if (entry.id.toString().trim().length > 0 && entry.govEntityName.toString().trim().length > 0) {
        newDataArray.push(entry);
      }
    });
    return newDataArray;
  }
}
