export const DASHBOARDDATA = {
  _source: 'cached',
  _comment: 'This is a response saved 5/11/20 from endpoint https://api.etpflash.com/api/dashboardsummary?format=json',
  SolicitationTotal: 5887281006,
  ActiveSolicitationTotal: 1778857816,
  ActiveSolicitationCount: 51253,
  PendingDataRequests: '1',
  solicitationovertime: {
    values: [
      561509734,
      435275893,
      392320523,
      584814438,
      544861605,
      521673289,
      148574944,
      37748072,
      80694043,
      35161985,
      35818935,
      37947524,
      0,
      8693701,
      132174,
      0
    ],
    labels: [
      2015,
      2016,
      2017,
      2018,
      2019,
      2020,
      2021,
      2022,
      2023,
      2024,
      2025,
      2026,
      2027,
      2028,
      2029,
      2033
    ]
  },
  agencybreakdown: {
    values: [
      729,
      48212,
      1200,
      138,
      8
    ],
    labels: [
      'DOTRES',
      'USN',
      null,
      null,
      null
    ]
  },
  primaryrequirement: {
    values: [
      1303537020,
      632161364,
      446602483,
      417105722,
      356339772
    ],
    labels: [
      'IT Services',
      'Professional Services',
      'Administrative, Logistics & Management',
      'Engineering, Scientific and Technical Services',
      'Facility Support Services'
    ]
  }
};
export const DASHBOARDDATAEMPTY = {
  SolicitationTotal: 0,
  ActiveSolicitationTotal: 0,
  ActiveSolicitationCount: 0,
  PendingDataRequests: '0',
  solicitationovertime: {
    values: [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0
    ],
    labels: [
      2015,
      2016,
      2017,
      2018,
      2019,
      2020,
      2021,
      2022,
      2023,
      2024,
      2025,
      2026,
      2027,
      2028,
      2029,
      2033
    ]
  },
  agencybreakdown: {
    values: [
      0,
      0,
      0,
      0,
      8
    ],
    labels: [
      '',
      '',
      '',
      '',
      ''
    ]
  },
  primaryrequirement: {
    values: [
      0,
      0,
      0,
      0,
      0
    ],
    labels: [
      '',
      '',
      '',
      '',
      ''
    ]
  }
};
