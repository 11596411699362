export const searchTestData = [
  {
    agencyid: '24505',
    solicitationnumber: 'SOL27817000007',
    id: 'OPP146718',
    status: 'Awarded',
    oppvalue: 4499,
    title: 'JORDAN OUTREACH COMMUNICATION SUPPORT',
    solicitationdate_value: '2016-12-16T00:00:00.000',
    primaryrequirement: 'Professional Services'
  },
  {
    agencyid: '24506',
    solicitationnumber: '129JGP19Q0132',
    id: 'FBO2797503',
    status: 'Post-RFP',
    oppvalue: null,
    title: 'Command Vehicle Build up',
    solicitationdate_value: null,
    primaryrequirement: 'Operations & Maintenance'
  },
  {
    agencyid: '24506',
    solicitationnumber: 'AG04T0S170005',
    id: 'FBO2222369',
    status: 'Awarded',
    oppvalue: null,
    title: 'Song Meter Acoustic Recorders',
    solicitationdate_value: 'null',
    primaryrequirement: 'Electric & Electronic Components & Parts'
  },
  {
    agencyid: '25030',
    solicitationnumber: '89303019NMA000012',
    id: 'FBO2780555',
    status: 'Other',
    oppvalue: null,
    title: 'U--Notice of Intent: The U.S. Department of Energy (DOE), Office of Administration',
    solicitationdate_value: null,
    primaryrequirement: 'Professional Services'
  },
  {
    agencyid: '25030',
    solicitationnumber: 'DESOL0007097',
    id: 'OPP44732',
    status: 'Awarded',
    oppvalue: 1402952,
    title: 'AEC IDAHO CLEAN PROJECT CORE CONTRACT (ICP CORE)',
    solicitationdate_value: '2015-03-13T00:00:00.000',
    primaryrequirement: 'Environment & Conservation Services'
  },
  {
    agencyid: '25097',
    solicitationnumber: '68HE0418Q0011',
    id: 'FBO2528174',
    status: 'Awarded',
    oppvalue: null,
    title: '66--Purchase one Pensky-Martens flash point test',
    solicitationdate_value: null,
    primaryrequirement: 'Medical & Scientific Equipment'
  },
  {
    agencyid: '25097',
    solicitationnumber: 'SOLR91600002',
    id: 'OPP129262',
    status: 'Awarded',
    oppvalue: 85000,
    title: 'NAVAJO AREA URANIUM MINES RESPONSE ASSESSMENT AND EVALUATION SERVICES FOR REGIONS 6 AND 9',
    solicitationdate_value: '2016-08-31T00:00:00.000',
    primaryrequirement: 'Architecture & Engineering Services'
  },
  {
    agencyid: '25097',
    solicitationnumber: '68HE0918R0014',
    id: 'OPP144045',
    status: 'Source Selection',
    oppvalue: 220000,
    title: 'AEC NAVAJO AREA ABANDONED MINES RESPONSE AND CONSTRUCTION SERVICES (AMRCS)',
    solicitationdate_value: '2019-04-11T00:00:00.000',
    primaryrequirement: 'Other Construction Services'
  },
  {
    agencyid: '25212',
    solicitationnumber: 'RFQNCI5372271',
    id: 'FBO2794990',
    status: 'Post-RFP',
    oppvalue: null,
    title: 'Single fluorescent molecule detection, multi-parameter imaging microscope for single nanoparticle (>20 nm) detection.',
    solicitationdate_value: '2019-08-08T16:22:00.000',
    primaryrequirement: 'Medical & Scientific Equipment'
  },
  {
    agencyid: '25223',
    solicitationnumber: 'HHSNIHNIDAAGRFP17026',
    id: 'OPP116012',
    status: 'Awarded',
    oppvalue: 456,
    title: 'DEVELOPMENT AND MAINTENANCE OF AN AGED NONHUMAN PRIMATE TISSUE BANK',
    solicitationdate_value: '2017-10-11T00:00:00.000',
    primaryrequirement: 'Engineering, Scientific and Technical Services'
  }
]
