import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { ChartsModule } from 'ng2-charts';

import { AgencySummaryComponent } from './agency-summary/agency-summary.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HomeComponent } from './home/home.component';
import { TopbarComponent } from './topbar/topbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { FooterComponent } from './footer/footer.component';
import { SmallCardComponent } from './small-card/small-card.component';
import { KeywordSearchComponent } from './keyword-search/keyword-search.component';
import { SsrReportsComponent } from './ssr-reports/ssr-reports.component';
import { DiagnosticPanelComponent } from './diagnostic-panel/diagnostic-panel.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NbChoicesModule} from "nb-choices";
import {AccordionModule} from "ngx-bootstrap/accordion";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {ModalModule} from "ngx-bootstrap/modal";
import {TabsModule} from "ngx-bootstrap/tabs";

@NgModule({
  declarations: [AgencySummaryComponent,
    SidebarComponent,
    HomeComponent,
    TopbarComponent,
    DashboardComponent,
    ProfileComponent,
    FooterComponent,
    SmallCardComponent,
    KeywordSearchComponent,
    SsrReportsComponent,
    DiagnosticPanelComponent
  ],
  imports: [CommonModule, RouterModule, DataTablesModule, ChartsModule, FormsModule, NbChoicesModule, ReactiveFormsModule, AccordionModule, BsDatepickerModule, NgxJsonViewerModule, ModalModule, TabsModule],
  exports: [
    TopbarComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent
  ]
})

export class InternalModule { }
