import { Action } from '@ngrx/store';


export enum ApiGetDataType {
  DASHBOARD_SUMMARY = 'dashboardsummary',
  HAS_DASHBOARD_SUMMARY = 'needs dashboardsummary',
  NEEDS_DASHBOARD_SUMMARY = 'has dashboardsummary',
  AGENCY_SUMMARY = 'agencysummary',
  HAS_AGENCY_SUMMARY = 'needs agencysummary',
  NEEDS_AGENCY_SUMMARY = 'has agencysummary',
  KEYWORD_SEARCH = 'getKeywordSearchCombined',
  HAS_KEYWORD_SEARCH = 'needs getKeywordSearchCombined',
  NEEDS_KEYWORD_SEARCH = 'has getKeywordSearchCombined',
  UPDATE_APP_STATE = 'app state',
  UPDATE_ERROR_LOG = 'error log'
}

export class GetDashboardData implements Action {
  readonly type = ApiGetDataType.DASHBOARD_SUMMARY;
  constructor(public payload: any) {}
}

export class HasDashboardData implements Action {
  readonly type = ApiGetDataType.HAS_DASHBOARD_SUMMARY;
  constructor(public payload: any) {}
}

export class NeedsDashboardData implements Action {
  readonly type = ApiGetDataType.NEEDS_DASHBOARD_SUMMARY;
  constructor(public payload: any) {}
}

export class GetAgencyData implements Action {
  readonly type = ApiGetDataType.AGENCY_SUMMARY;
  constructor(public payload: any) {}
}

export class HasAgencyData implements Action {
  readonly type = ApiGetDataType.HAS_AGENCY_SUMMARY;
  constructor(public payload: any) {}
}

export class NeedsAgencyData implements Action {
  readonly type = ApiGetDataType.NEEDS_AGENCY_SUMMARY;
  constructor(public payload: any) {}
}

export class GetKeywordData implements Action {
  readonly type = ApiGetDataType.KEYWORD_SEARCH;
  constructor(public payload: any) {}
}

export class HasKeywordData implements Action {
  readonly type = ApiGetDataType.HAS_KEYWORD_SEARCH;
  constructor(public payload: any) {}
}

export class NeedsKeywordData implements Action {
  readonly type = ApiGetDataType.NEEDS_KEYWORD_SEARCH;
  constructor(public payload: any) {}
}

export class UpdateAppState implements Action {
  readonly type = ApiGetDataType.UPDATE_APP_STATE;
  constructor(public payload: any) {}
}

export class UpdateErrorLog implements Action {
  readonly type = ApiGetDataType.UPDATE_ERROR_LOG;
  constructor(public payload: any) {}
}

export type All =
  | GetDashboardData
  | HasDashboardData
  | NeedsDashboardData
  | GetAgencyData
  | HasAgencyData
  | NeedsAgencyData
  | GetKeywordData
  | HasKeywordData
  | NeedsKeywordData
  | UpdateAppState
  | UpdateErrorLog;
