export const ERRORREPORT =
  [
  {
    _source: 'cached',
    _comment: 'This is a response saved 5/11/20 from endpoint https://api.etpflash.com/api/getErrorReportDetailed?start_date=01012000&end_date=05152020&ids=24505,26687,26688&top_n=5',
    key: 'Data/govwin/raw/all2/apilogs/24505_ErrorLog.txt',
    size: 1473,
    last_modified: '2019-04-20 03:17:01+00:00',
    url: 'https://cgietp-flash.s3.amazonaws.com/Data/govwin/raw/all2/apilogs/24505_ErrorLog.txt',
    agency_id: '999999999',
    agency_name: 'USAID',
    agency_desc: 'Agency For International Development',
    agency_top_n_error_list: [
      {
        logmessage: 'Rate Limit reached, Sleeping for a bit',
        timestamp: '2019-04-20T03:17:00'
      },
      {
        logmessage: 'Rate Limit reached, Sleeping for a bit',
        timestamp: '2019-04-20T03:03:37'
      },
      {
        logmessage: 'Rate Limit reached, Sleeping for a bit',
        timestamp: '2019-04-20T02:51:50'
      },
      {
        logmessage: 'Rate Limit reached, Sleeping for a bit',
        timestamp: '2019-04-20T02:40:20'
      }
    ]
  },
  {
    key: 'Data/govwin/raw/all2/apilogs/26687_ErrorLog.txt',
    size: 18467,
    last_modified: '2020-02-06 19:20:48+00:00',
    url: 'https://cgietp-flash.s3.amazonaws.com/Data/govwin/raw/all2/apilogs/26687_ErrorLog.txt',
    agency_id: '26687',
    agency_name: 'DARPA',
    agency_desc: 'DEFENSE ADVANCED RESEARCH PROJECTS AGENCY',
    agency_top_n_error_list: [
      {
        location: 'In the main loop 3',
        searchparams: {
          max: '100',
          sort: 'createdDate',
          order: 'desc',
          oppSelectionDateFrom: '2015-08-22T19:20:43',
          oppSelectionDateTo: '2015-09-19T19:20:43',
          govEntityId: '26687'
        },
        errormessage: [
          {
            file_name: 'FlashBatchRunner.py',
            line_num: '96',
            func_name: '<module>',
            line_content: 'oppdata,rcount=objclient.oppurtunitySearch(searchparams,logfilepath,errorfilepath)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '436',
            func_name: 'oppurtunitySearch',
            line_content: 'oppdata,status=self.getOppurtunities(searchparams)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '336',
            func_name: 'getOppurtunities',
            line_content: 'r,status = self.apicall(url)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '296',
            func_name: 'apicall',
            line_content: 'self.setTokenRefresh()'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '279',
            func_name: 'setTokenRefresh',
            line_content: 'self._authtoken=json.loads(r.text)[\'access_token\']KeyError: \'access_token\''
          }
        ],
        timestamp: '2020-02-06T19:20:47'
      },
      {
        location: 'In the main loop 3',
        searchparams: {
          max: '100',
          sort: 'createdDate',
          order: 'desc',
          oppSelectionDateFrom: '2015-07-25T19:20:43',
          oppSelectionDateTo: '2015-08-22T19:20:43',
          govEntityId: '26687'
        },
        errormessage: [
          {
            file_name: 'FlashBatchRunner.py',
            line_num: '96',
            func_name: '<module>',
            line_content: 'oppdata,rcount=objclient.oppurtunitySearch(searchparams,logfilepath,errorfilepath)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '436',
            func_name: 'oppurtunitySearch',
            line_content: 'oppdata,status=self.getOppurtunities(searchparams)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '336',
            func_name: 'getOppurtunities',
            line_content: 'r,status = self.apicall(url)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '296',
            func_name: 'apicall',
            line_content: 'self.setTokenRefresh()'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '279',
            func_name: 'setTokenRefresh',
            line_content: 'self._authtoken=json.loads(r.text)[\'access_token\']KeyError: \'access_token\''
          }
        ],
        timestamp: '2020-02-06T19:20:47'
      },
      {
        location: 'In the main loop 3',
        searchparams: {
          max: '100',
          sort: 'createdDate',
          order: 'desc',
          oppSelectionDateFrom: '2015-06-27T19:20:43',
          oppSelectionDateTo: '2015-07-25T19:20:43',
          govEntityId: '26687'
        },
        errormessage: [
          {
            file_name: 'FlashBatchRunner.py',
            line_num: '96',
            func_name: '<module>',
            line_content: 'oppdata,rcount=objclient.oppurtunitySearch(searchparams,logfilepath,errorfilepath)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '436',
            func_name: 'oppurtunitySearch',
            line_content: 'oppdata,status=self.getOppurtunities(searchparams)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '336',
            func_name: 'getOppurtunities',
            line_content: 'r,status = self.apicall(url)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '296',
            func_name: 'apicall',
            line_content: 'self.setTokenRefresh()'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '279',
            func_name: 'setTokenRefresh',
            line_content: 'self._authtoken=json.loads(r.text)[\'access_token\']KeyError: \'access_token\''
          }
        ],
        timestamp: '2020-02-06T19:20:46'
      },
      {
        location: 'In the main loop 3',
        searchparams: {
          max: '100',
          sort: 'createdDate',
          order: 'desc',
          oppSelectionDateFrom: '2015-05-30T19:20:43',
          oppSelectionDateTo: '2015-06-27T19:20:43',
          govEntityId: '26687'
        },
        errormessage: [
          {
            file_name: 'FlashBatchRunner.py',
            line_num: '96',
            func_name: '<module>',
            line_content: 'oppdata,rcount=objclient.oppurtunitySearch(searchparams,logfilepath,errorfilepath)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '436',
            func_name: 'oppurtunitySearch',
            line_content: 'oppdata,status=self.getOppurtunities(searchparams)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '336',
            func_name: 'getOppurtunities',
            line_content: 'r,status = self.apicall(url)'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '296',
            func_name: 'apicall',
            line_content: 'self.setTokenRefresh()'
          },
          {
            file_name: '/home/ubuntu/govwin2/fileservice/FlashGovwinclient.py',
            line_num: '279',
            func_name: 'setTokenRefresh',
            line_content: 'self._authtoken=json.loads(r.text)[\'access_token\']KeyError: \'access_token\''
          }
        ],
        timestamp: '2020-02-06T19:20:46'
      }
    ]
  },
  {
    key: 'Data/govwin/raw/all2/apilogs/26688_ErrorLog.txt',
    size: 1136,
    last_modified: '2020-02-07 02:24:25+00:00',
    url: 'https://cgietp-flash.s3.amazonaws.com/Data/govwin/raw/all2/apilogs/26688_ErrorLog.txt',
    agency_id: '26688',
    agency_name: 'DCMA',
    agency_desc: 'DEFENSE CONTRACT MANAGEMENT AGENCY',
    agency_top_n_error_list: [
      {
        logmessage: {
          errors: [
            {
              logref: 'searchParamsCommand.invalidDate',
              message: 'Property [oppSelectionDateFrom] with value [2015-03-08T02:23:52] is not a valid date or not valid relative date option. Dates should be in the format [mm/dd/yyyy] or [yyyy-MM-dd] or [yyyy-MM-ddTHH:mm:ss] or [yyyy-MM-ddTHH:mm:ss.S] or valid Past Relative Date option.',
              links: {
                resource: {
                  href: 'https://iq.govwin.com//com.deltek.istech.ws.command.SearchParamsCommand%4058485151/index'
                }
              }
            }
          ]
        },
        timestamp: '2020-02-07T02:24:24'
      },
      {
        logmessage: {
          errors: [
            {
              logref: 'searchParamsCommand.invalidDate',
              message: 'Property [oppSelectionDateTo] with value [2015-03-08T02:23:52] is not a valid date or not valid relative date option. Dates should be in the format [mm/dd/yyyy] or [yyyy-MM-dd] or [yyyy-MM-ddTHH:mm:ss] or [yyyy-MM-ddTHH:mm:ss.S] or valid Past Relative Date option.',
              links: {
                resource: {
                  href: 'https://iq.govwin.com/neo-ws/com.deltek.istech.ws.command.SearchParamsCommand%404fdb1c8b/index'
                }
              }
            }
          ]
        },
        timestamp: '2020-02-07T02:24:13'
      },
      {
        logmessage: 'Keyboard interupt received: Quitting the execution n',
        timestamp: '2020-02-06T21:47:07'
      }
    ]
  }
];
