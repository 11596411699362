import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroupDirective, NgForm} from '@angular/forms';
import { FormGroup, FormBuilder } from  '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { ApiService } from '../../services/api.service';
import { AppComponent } from '../../app.component';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { User } from '../../data-structure-models/user';
import { AppState, selectAuthState } from '../../store/app.states';
import { LogIn } from '../../store/actions/auth.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  // @Output() user = new EventEmitter<string>();
  loginForm: FormGroup;
  username: string;
  private response;
  private etpflashtoken: string;
  user: User = new User();
  getState: Observable<any>;
  errorMessage: string | null;
  currentYear: number;

  constructor(private apiService: ApiService,
              private formBuilder: FormBuilder,
              private router: Router,
              private cookieService: CookieService,
              private appComponent: AppComponent,
              private store: Store<AppState>
  ) {
    this.createLoginForm();
    this.getState = this.store.select(selectAuthState);
    this.currentYear = new Date().getFullYear();
  }


  ngOnInit() {
    this.getState.subscribe((state) => {
      this.errorMessage = state.errorMessage;
      if (state.user && state.user.email !== '') {
      }
    });
    setTimeout(() => {$('#slider').toggleClass('open'); }, 300);
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      password: [''],
      username: ['']
    });
  }

  login() {
    let payload = {
    username: this.user.email,
    password: this.user.password
  };
    this.store.dispatch(new LogIn(payload));
  }

  onSubmit() {
    // this.getTokenPost();
    this.login();
  }
  keydownFunction() {
    this.errorMessage = '';
}
}
