import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {selectAuthState} from '../store/app.states';


import { ApiService } from '../services/api.service';
import {map} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})

export class InternalGuard implements CanActivate {
  getState: Observable<any>;
  authLevel = 0;

  constructor(private apiService: ApiService, private router: Router, private store: Store<any>) {
    this.getState = this.store.select(selectAuthState);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const redirectUrl = route['_routerState']['url'];
    console.log('in guard!');
    //diagnostic_panel
    if (route.url && route.url.length > 0 && route.url[0].path && route.url[0].path.indexOf('diagnostic_panel') !== -1) {
      console.log(route.url[0].path);
      return this.store.select(user => user)
        .pipe(map(user => {
          if (user && user.auth &&
            user.auth.user &&
            this.apiService.getToken()) {
            // console.log('user level: ' + user.auth.user.userLevel);
            return true;
          } else {
            console.log('ERROR: insufficient account privileges to access this page');
            this.router.navigateByUrl(
              this.router.createUrlTree(
                ['/dashboard'], {
                  queryParams: {
                    redirectUrl
                  }
                }
              )
            );
            return false;
          }
        }));
    }
    if (this.apiService.getToken()) {
      return true;
    }

    this.router.navigateByUrl(
      this.router.createUrlTree(
        ['/login'], {
          queryParams: {
            redirectUrl
          }
        }
      )
    );

    return false;
  }
}
