import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState, selectAuthState } from './store/app.states';
import {LogIn, LogOut} from './store/actions/auth.actions';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  getState: Observable<any>;
  isAuthenticated: false;
  user = null;
  errorMessage = null;

  title = 'flash';
  username: string;
  isLoggedIn = false;
  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {
    this.getState = this.store.select(selectAuthState);
  }

  ngOnInit() {
    // check login status
    this.login();

    // get login status
    this.getState.subscribe((state) => {
      this.isLoggedIn = state.isAuthenticated;
      this.username = state.user;
      this.errorMessage = state.errorMessage;
    });
  }

  logout($event) {
    console.log('log out from app component');
    this.store.dispatch(new LogOut());
  }

  login() {
    // check if logged in
    let payload = {username: '', password: ''};
    this.store.dispatch(new LogIn(payload));
  }
}
