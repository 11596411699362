import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationModule } from './authentication/authentication.module';
import { InternalModule } from './internal/internal.module';
import { LoginComponent } from './authentication/login/login.component';
import { AgencySummaryComponent } from './internal/agency-summary/agency-summary.component';
import { SsrReportsComponent } from './internal/ssr-reports/ssr-reports.component';
import { DiagnosticPanelComponent } from './internal/diagnostic-panel/diagnostic-panel.component';
import { KeywordSearchComponent } from './internal/keyword-search/keyword-search.component';
import { InternalGuard } from './internal/internal.guard';
import { HomeComponent } from './internal/home/home.component';
import { DashboardComponent } from './internal/dashboard/dashboard.component';
import { ProfileComponent } from './internal/profile/profile.component';
import { SidebarComponent } from './internal/sidebar/sidebar.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },

  // home route protected by auth guard
  { path: 'dashboard', component: DashboardComponent, canActivate: [InternalGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [InternalGuard] },
  { path: 'profile/:id', component: ProfileComponent, canActivate: [InternalGuard] },
  { path: 'ssr_reports', component: SsrReportsComponent, canActivate: [InternalGuard] },
  { path: 'diagnostic_panel', component: DiagnosticPanelComponent, canActivate: [InternalGuard] },
  { path: 'keyword_search', component: KeywordSearchComponent, canActivate: [InternalGuard] },
  { path: 'agency_summary', component: AgencySummaryComponent, canActivate: [InternalGuard] },

  // otherwise redirect to home
  { path: '**', redirectTo: '/dashboard' }
  ];

  /*




	{
		path: '',
		redirectTo: '/dashboard',
		pathMatch: 'full',
    canActivate: [InternalGuard]
  },
	{
		path: '',
		children: [
			{
				path: 'dashboard',
				component: DashboardComponent,
				canActivate: [InternalGuard]
			},
			{
				path: 'ssr_reports',
				component: SsrReportsComponent,
				canActivate: [InternalGuard]
			},
			{
				path: 'keyword_search',
				component: KeywordSearchComponent,
				canActivate: [InternalGuard]
			},
			{
				path: 'agency_summary',
				component: AgencySummaryComponent,
				canActivate: [InternalGuard]
			},
      {
        path: 'login',
        component: LoginComponent,
        outlet: 'authentication'
      },
		]
	},
	{
		path: '**',
		redirectTo: '/dashboard',
		pathMatch: 'full',
    canActivate: [InternalGuard]
  }
];*/

@NgModule({
  imports: [AuthenticationModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
