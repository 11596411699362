import { Action } from '@ngrx/store';


export enum AuthActionTypes {
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  FOUND_CREDENTIALS = '[Auth] Found Credentials',
  // NOT_FOUND_CREDENTIALS is hit if not logged on during check, different that failure because no error
  NOT_FOUND_CREDENTIALS = '[Auth] Not Found Credentials',
  SIGNUP = '[Auth] Signup',
  SIGNUP_SUCCESS = '[Auth] Signup Success',
  SIGNUP_FAILURE = '[Auth] Signup Failure',
  LOGOUT = '[Auth] Logout',
  GET_USER_DATA = '[Auth] Get User',
  SET_USER_DATA = '[Auth] Update User',
  GET_USER_PERMISSIONS = '[Auth] Get User Permissions',
}

export class LogIn implements Action {
  readonly type = AuthActionTypes.LOGIN;
  constructor(public payload: any) {}
}

export class LogInSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public payload: any) {}
}

export class FoundCredentials implements Action {
  readonly type = AuthActionTypes.FOUND_CREDENTIALS;
  constructor(public payload: any) {}
}

export class NotFoundCredentials implements Action {
  readonly type = AuthActionTypes.NOT_FOUND_CREDENTIALS;
  constructor(public payload: any) {}
}

export class LogInFailure implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILURE;
  constructor(public payload: any) {}
}

export class SignUp implements Action {
  readonly type = AuthActionTypes.SIGNUP;
  constructor(public payload: any) {}
}

export class SignUpSuccess implements Action {
  readonly type = AuthActionTypes.SIGNUP_SUCCESS;
  constructor(public payload: any) {}
}

export class SignUpFailure implements Action {
  readonly type = AuthActionTypes.SIGNUP_FAILURE;
  constructor(public payload: any) {}
}

export class LogOut implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}
export class GetUserData implements Action {
  readonly type = AuthActionTypes.GET_USER_DATA;
  constructor(public payload: any) {}
}
export class GetUserPermissions implements Action {
  readonly type = AuthActionTypes.GET_USER_PERMISSIONS;
  constructor(public payload: any) {}
}
export class SetUserData implements Action {
  readonly type = AuthActionTypes.SET_USER_DATA;
  constructor(public payload: any) {}
}
export class UpdateUserData implements Action {
  readonly type = AuthActionTypes.SET_USER_DATA;
  constructor(public payload: any) {}
}

export type All =
  | LogIn
  | LogInSuccess
  | FoundCredentials
  | NotFoundCredentials
  | LogInFailure
  | SignUp
  | SignUpSuccess
  | SignUpFailure
  | LogOut
  | GetUserData
  | GetUserPermissions
  | SetUserData
  | UpdateUserData;
