export const AGENCYDATA =
[
  {
    _source: 'cached',
    _comment: 'This is a response saved 5/11/20 from endpoint https://api.etpflash.com/api/agencysummary?format=json',
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: ' ',
    Recordcount: '-1',
    RecordcountRS: 160969,
    Reldoccount: 1,
    govEntityDescription: '',
    govEntityName: '',
    id: ''
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: ' ',
    Recordcount: '-1',
    RecordcountRS: 160360,
    Reldoccount: 1,
    govEntityDescription: '',
    govEntityName: '',
    id: ''
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: ' ',
    Recordcount: '-1',
    RecordcountRS: 155247,
    Reldoccount: 1,
    govEntityDescription: '',
    govEntityName: '',
    id: ''
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: ' ',
    Recordcount: '-1',
    RecordcountRS: 150018,
    Reldoccount: 1,
    govEntityDescription: '',
    govEntityName: '',
    id: ''
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: ' ',
    Recordcount: '-1',
    RecordcountRS: 145939,
    Reldoccount: 1,
    govEntityDescription: '',
    govEntityName: '',
    id: ''
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: ' ',
    Recordcount: '-1',
    RecordcountRS: 145939,
    Reldoccount: 1,
    govEntityDescription: '',
    govEntityName: '',
    id: ''
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: ' ',
    Recordcount: '-1',
    RecordcountRS: 145939,
    Reldoccount: 1,
    govEntityDescription: '',
    govEntityName: '',
    id: ''
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '5931',
    Filedownloadratio: 99.79,
    FilesDownloadCount: 7459,
    FilesFailed: 15,
    FilesSucceded: 7154,
    LastProcessed: '2019-04-21 14:48:33',
    Recordcount: '27706',
    RecordcountRS: 4232,
    Reldoccount: 1,
    govEntityDescription: 'Department of State',
    govEntityName: 'STATE',
    id: '25735'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '2726',
    Filedownloadratio: 88.11,
    FilesDownloadCount: 6729,
    FilesFailed: 642,
    FilesSucceded: 4759,
    LastProcessed: '2019-04-22 16:04:03',
    Recordcount: '21660',
    RecordcountRS: 2261,
    Reldoccount: 1,
    govEntityDescription: 'National Aeronautics and Space Adminstration',
    govEntityName: 'NASA',
    id: '25455'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '1873',
    Filedownloadratio: 87.56,
    FilesDownloadCount: 5515,
    FilesFailed: 686,
    FilesSucceded: 4829,
    LastProcessed: '2019-04-19 22:50:11',
    Recordcount: '5425',
    RecordcountRS: 1346,
    Reldoccount: 1,
    govEntityDescription: 'Agency For International Development',
    govEntityName: 'USAID',
    id: '999999999'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '467',
    Filedownloadratio: 99.42,
    FilesDownloadCount: 1646,
    FilesFailed: 9,
    FilesSucceded: 1544,
    LastProcessed: '2020-03-26 00:53:38',
    Recordcount: '1046',
    RecordcountRS: 356,
    Reldoccount: 1,
    govEntityDescription: 'Centers for Medicare and Medicaid Services',
    govEntityName: 'CMS',
    id: '26220'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '94',
    Filedownloadratio: 99.14,
    FilesDownloadCount: 357,
    FilesFailed: 3,
    FilesSucceded: 344,
    LastProcessed: '2019-08-06 16:31:17',
    Recordcount: '364',
    RecordcountRS: 40,
    Reldoccount: 1,
    govEntityDescription: 'US Courts',
    govEntityName: 'USC',
    id: '25816'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '1689',
    Filedownloadratio: 33.96,
    FilesDownloadCount: 2815,
    FilesFailed: 1859,
    FilesSucceded: 956,
    LastProcessed: '2019-08-04 15:46:58',
    Recordcount: '3965',
    RecordcountRS: 1313,
    Reldoccount: 1,
    govEntityDescription: 'Environmental Protection Agency',
    govEntityName: 'EPA',
    id: '25097'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '1037',
    Filedownloadratio: 71.41,
    FilesDownloadCount: 3249,
    FilesFailed: 929,
    FilesSucceded: 2320,
    LastProcessed: '2019-08-04 22:28:32',
    Recordcount: '4657',
    RecordcountRS: 729,
    Reldoccount: 1,
    govEntityDescription: 'Department of Treasury',
    govEntityName: 'DOTRES',
    id: '25803'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '22277',
    Filedownloadratio: 100.0,
    FilesDownloadCount: 606,
    FilesFailed: 0,
    FilesSucceded: 601,
    LastProcessed: '2019-08-15 15:58:45',
    Recordcount: '395750',
    RecordcountRS: 6291,
    Reldoccount: 1,
    govEntityDescription: 'Department of Interior',
    govEntityName: 'DOI',
    id: '25321'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '8782',
    Filedownloadratio: 99.66,
    FilesDownloadCount: 10283,
    FilesFailed: 34,
    FilesSucceded: 9913,
    LastProcessed: '2020-03-26 19:19:17',
    Recordcount: '189644',
    RecordcountRS: 6470,
    Reldoccount: 1,
    govEntityDescription: 'The U.S. Department of Health and Human Services',
    govEntityName: 'HHS',
    id: '25212'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '10252',
    Filedownloadratio: 98.79,
    FilesDownloadCount: 4235,
    FilesFailed: 50,
    FilesSucceded: 4080,
    LastProcessed: '2019-08-19 21:56:43',
    Recordcount: '73451',
    RecordcountRS: 7950,
    Reldoccount: 1,
    govEntityDescription: 'The United States Department of Agriculture',
    govEntityName: 'USDA',
    id: '24506'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '18080',
    Filedownloadratio: 99.92,
    FilesDownloadCount: 4953,
    FilesFailed: 4,
    FilesSucceded: 4806,
    LastProcessed: '2019-08-21 21:40:59',
    Recordcount: '152116',
    RecordcountRS: 10493,
    Reldoccount: 1,
    govEntityDescription: 'The Department of Justice',
    govEntityName: 'DOJ',
    id: '25358'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '3097',
    Filedownloadratio: 99.86,
    FilesDownloadCount: 3004,
    FilesFailed: 4,
    FilesSucceded: 2919,
    LastProcessed: '2020-03-25 21:37:02',
    Recordcount: '41745',
    RecordcountRS: null,
    Reldoccount: 1,
    govEntityDescription: 'National Institute Of Health',
    govEntityName: 'NIH',
    id: '25223'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '1074',
    Filedownloadratio: 99.78,
    FilesDownloadCount: 934,
    FilesFailed: 2,
    FilesSucceded: 900,
    LastProcessed: '2020-01-07 20:06:11',
    Recordcount: '2755',
    RecordcountRS: null,
    Reldoccount: 1,
    govEntityDescription: 'Social Security Adminstration',
    govEntityName: 'SSA',
    id: '25685'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '31942',
    Filedownloadratio: 99.78,
    FilesDownloadCount: 8205,
    FilesFailed: 18,
    FilesSucceded: 8088,
    LastProcessed: '2020-01-07 21:43:49',
    Recordcount: '1325662',
    RecordcountRS: null,
    Reldoccount: 1,
    govEntityDescription: 'Veterans Affairs',
    govEntityName: 'VA',
    id: '25834'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '3286',
    Filedownloadratio: 99.55,
    FilesDownloadCount: 1128,
    FilesFailed: 5,
    FilesSucceded: 1105,
    LastProcessed: '2019-09-02 01:06:23',
    Recordcount: '18986',
    RecordcountRS: null,
    Reldoccount: 1,
    govEntityDescription: 'Department Of Energy',
    govEntityName: 'DOE(Energy)',
    id: '25030'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '13822',
    Filedownloadratio: 99.65,
    FilesDownloadCount: 10718,
    FilesFailed: 36,
    FilesSucceded: 10163,
    LastProcessed: '2019-09-03 19:57:16',
    Recordcount: '187036',
    RecordcountRS: 10675,
    Reldoccount: 1,
    govEntityDescription: 'Department Of Homeland Security',
    govEntityName: 'DHS',
    id: '25265'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '67516',
    Filedownloadratio: 99.91,
    FilesDownloadCount: 3273,
    FilesFailed: 3,
    FilesSucceded: 3193,
    LastProcessed: '2020-03-26 03:24:00',
    Recordcount: '4444100',
    RecordcountRS: 48212,
    Reldoccount: 1,
    govEntityDescription: 'The United States Navy',
    govEntityName: 'NAVY',
    id: '25499'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '4041',
    Filedownloadratio: 99.93,
    FilesDownloadCount: 8786,
    FilesFailed: 6,
    FilesSucceded: 8474,
    LastProcessed: '2020-01-07 17:54:18',
    Recordcount: '19431',
    RecordcountRS: 3245,
    Reldoccount: 1,
    govEntityDescription: 'United States Marine Corps',
    govEntityName: 'USMC',
    id: '25423'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '76557',
    Filedownloadratio: 99.92,
    FilesDownloadCount: 3893,
    FilesFailed: 3,
    FilesSucceded: 3791,
    LastProcessed: '2020-03-23 17:54:57',
    Recordcount: '4162894',
    RecordcountRS: 59804,
    Reldoccount: 1,
    govEntityDescription: 'Department of the Army',
    govEntityName: 'ARMY',
    id: '24696'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0.0,
    FileCount: '34053',
    Filedownloadratio: 99.76,
    FilesDownloadCount: 12964,
    FilesFailed: 30,
    FilesSucceded: 12271,
    LastProcessed: '2020-01-09 15:53:54',
    Recordcount: '1417214',
    RecordcountRS: 24688,
    Reldoccount: 1,
    govEntityDescription: 'Department of the Air Force',
    govEntityName: 'DAF',
    id: '24551'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '708',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-26 21:19:38',
    Recordcount: '1412',
    RecordcountRS: 1200,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE ADVANCED RESEARCH PROJECTS AGENCY',
    govEntityName: 'DARPA',
    id: '26687'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '580',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-26 21:25:18',
    Recordcount: '1051',
    RecordcountRS: 511,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE COMMISSARY AGENCY',
    govEntityName: 'DCA',
    id: '116490'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '1',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-26 21:27:41',
    Recordcount: '5',
    RecordcountRS: 1,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE CONTRACT AUDIT AGENCY',
    govEntityName: 'DCAA',
    id: '26705'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '95',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-26 21:27:42',
    Recordcount: '402',
    RecordcountRS: 53,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE CONTRACT MANAGEMENT AGENCY',
    govEntityName: 'DCMA',
    id: '26688'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '218',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-26 21:27:42',
    Recordcount: '380',
    RecordcountRS: 138,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE FINANCE AND ACCOUNTING SERVICE',
    govEntityName: 'DFAAS',
    id: '26706'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '4823',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-26 21:37:04',
    Recordcount: '14223',
    RecordcountRS: 4316,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE INFORMATION SYSTEMS AGENCY',
    govEntityName: 'DISA',
    id: '26707'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '217',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-26 22:29:08',
    Recordcount: '450',
    RecordcountRS: 127,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE INTELLIGENCE AGENCY',
    govEntityName: 'DIA',
    id: '24920'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '4',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-26 22:30:30',
    Recordcount: '3',
    RecordcountRS: 8,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE LEGAL SERVICES AGENCY',
    govEntityName: 'DLSA',
    id: '24921'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '216',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 04:56:12',
    Recordcount: '436',
    RecordcountRS: 183,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE SECURITY COOPERATION AGENCY',
    govEntityName: 'DSCA',
    id: '24922'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '9',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 04:56:23',
    Recordcount: '8',
    RecordcountRS: 7,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE TECHNICAL INFORMATION CENTER',
    govEntityName: 'DTIC',
    id: '126284'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '1',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 04:56:24',
    Recordcount: '0',
    RecordcountRS: 1,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE TECHNOLOGY SECURITY ADMINISTRATION',
    govEntityName: 'DTSA',
    id: '24950'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '284',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 04:56:25',
    Recordcount: '651',
    RecordcountRS: 198,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE THREAT REDUCTION AGENCY',
    govEntityName: 'DTRA',
    id: '26701'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '368',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 04:58:02',
    Recordcount: '566',
    RecordcountRS: 300,
    Reldoccount: 1,
    govEntityDescription: 'MISSILE DEFENSE AGENCY',
    govEntityName: 'MDA',
    id: '26704'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '333',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 04:59:34',
    Recordcount: '1040',
    RecordcountRS: 194,
    Reldoccount: 1,
    govEntityDescription: 'NATIONAL GEOSPATIAL-INTELLIGENCE AGENCY',
    govEntityName: 'NGA',
    id: '26712'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '17',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:21',
    Recordcount: '69',
    RecordcountRS: 2,
    Reldoccount: 1,
    govEntityDescription: 'NATIONAL RECONNAISSANCE OFFICE',
    govEntityName: 'NRO',
    id: '24936'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:22',
    Recordcount: '-1',
    RecordcountRS: 0,
    Reldoccount: 1,
    govEntityDescription: 'OFFICE OF ECONOMIC ADJUSTMENT',
    govEntityName: 'OOEA',
    id: '24952'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '6',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:23',
    Recordcount: '18',
    RecordcountRS: 4,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE ACQUISITION UNIVERSITY',
    govEntityName: 'DAU',
    id: '26686'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:25',
    Recordcount: '0',
    RecordcountRS: 0,
    Reldoccount: 1,
    govEntityDescription: 'DEFENSE CRIMINAL INVESTIGATIVE SERVICE',
    govEntityName: 'DCIS',
    id: '24919'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '13',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:26',
    Recordcount: '29',
    RecordcountRS: 10,
    Reldoccount: 1,
    govEntityDescription: 'AIR NATIONAL GUARD',
    govEntityName: 'ANG',
    id: '24667'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:34',
    Recordcount: '-1',
    RecordcountRS: 0,
    Reldoccount: 1,
    govEntityDescription: 'AIR NATIONAL GUARD READINESS CENTER',
    govEntityName: 'ANGRC',
    id: '147351'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:35',
    Recordcount: '-1',
    RecordcountRS: 0,
    Reldoccount: 1,
    govEntityDescription: 'AIR NATIONAL GUARD SUPPORT CENTER',
    govEntityName: 'ANGSC',
    id: '24668'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:36',
    Recordcount: '-1',
    RecordcountRS: 0,
    Reldoccount: 1,
    govEntityDescription: 'AIR NATIONAL GUARD TRAINING AND EDUCATION CENTER',
    govEntityName: 'ANGTAEC',
    id: '147394'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:38',
    Recordcount: '-1',
    RecordcountRS: 0,
    Reldoccount: 1,
    govEntityDescription: 'NATIONAL INTELLIGENCE UNIVERSITY',
    govEntityName: 'NIU',
    id: '146302'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '9',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:39',
    Recordcount: '25',
    RecordcountRS: 5,
    Reldoccount: 1,
    govEntityDescription: 'US AFRICA COMMAND',
    govEntityName: 'UAC',
    id: '126039'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '44',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:39',
    Recordcount: '66',
    RecordcountRS: 33,
    Reldoccount: 1,
    govEntityDescription: 'US CENTRAL COMMAND',
    govEntityName: 'UCC',
    id: '24970'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:58',
    Recordcount: '6',
    RecordcountRS: 0,
    Reldoccount: 1,
    govEntityDescription: 'US EUROPEAN COMMAND',
    govEntityName: 'UEC',
    id: '27001'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '5',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:03:59',
    Recordcount: '18',
    RecordcountRS: 4,
    Reldoccount: 1,
    govEntityDescription: 'US INDO-PACIFIC COMMAND',
    govEntityName: 'UIC',
    id: '24975'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '9',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:04:19',
    Recordcount: '12',
    RecordcountRS: 6,
    Reldoccount: 1,
    govEntityDescription: 'US NORTHERN COMMAND',
    govEntityName: 'UNC',
    id: '24974'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '57',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:04:25',
    Recordcount: '81',
    RecordcountRS: 42,
    Reldoccount: 1,
    govEntityDescription: 'US SOUTHERN COMMAND',
    govEntityName: 'USC',
    id: '24976'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '10',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:04:26',
    Recordcount: '32',
    RecordcountRS: 3,
    Reldoccount: 1,
    govEntityDescription: 'US CYBER COMMAND',
    govEntityName: 'UCC',
    id: '146993'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '977',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:04:32',
    Recordcount: '2557',
    RecordcountRS: 769,
    Reldoccount: 1,
    govEntityDescription: 'US SPECIAL OPERATIONS COMMAND',
    govEntityName: 'USOC',
    id: '24985'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '18',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:09:31',
    Recordcount: '24',
    RecordcountRS: 15,
    Reldoccount: 1,
    govEntityDescription: 'US STRATEGIC COMMAND',
    govEntityName: 'USC',
    id: '24986'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '539',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:09:49',
    Recordcount: '934',
    RecordcountRS: 453,
    Reldoccount: 1,
    govEntityDescription: 'US TRANSPORTATION COMMAND',
    govEntityName: 'UTC',
    id: '24987'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '3',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:20:02',
    Recordcount: '3',
    RecordcountRS: 1,
    Reldoccount: 1,
    govEntityDescription: 'OFFICE OF THE DIRECTOR OF NATIONAL INTELLIGENCE',
    govEntityName: 'OOTDONI',
    id: '126454'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '1',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:20:04',
    Recordcount: '0',
    RecordcountRS: 2,
    Reldoccount: 1,
    govEntityDescription: 'BUREAU OF INTELLIGENCE AND RESEARCH (CODE INR)',
    govEntityName: 'BOIAR(I',
    id: '26766'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '2',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:20:12',
    Recordcount: '1',
    RecordcountRS: 1,
    Reldoccount: 1,
    govEntityDescription: 'OFFICE OF TERRORISM AND FINANCIAL INTELLIGENCE',
    govEntityName: 'OOTAFI',
    id: '125026'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '1',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:20:15',
    Recordcount: '4',
    RecordcountRS: 1,
    Reldoccount: 1,
    govEntityDescription: 'NATIONAL SECURITY AGENCY',
    govEntityName: 'NSA',
    id: '24937'
  },
  {
    ESemptycount: 0,
    ESindexcount: 0,
    EsIndexratio: 0,
    FileCount: '0',
    Filedownloadratio: 0,
    FilesDownloadCount: 0,
    FilesFailed: 0,
    FilesSucceded: 0,
    LastProcessed: '2020-03-31 05:20:16',
    Recordcount: '9',
    RecordcountRS: 0,
    Reldoccount: 1,
    govEntityDescription: 'OFFICE OF NAVAL INTELLIGENCE',
    govEntityName: 'OONI',
    id: '25609'
  }
];
export const AGENCYDATA_EMPTY =
  [
    {
      ESemptycount: 0,
      ESindexcount: 0,
      EsIndexratio: 0,
      FileCount: '0',
      Filedownloadratio: 0,
      FilesDownloadCount: 0,
      FilesFailed: 0,
      FilesSucceded: 0,
      LastProcessed: ' ',
      Recordcount: '0',
      RecordcountRS: 0,
      Reldoccount: 0,
      govEntityDescription: '',
      govEntityName: '',
      id: '1'
    }
  ];
