import { Component, OnDestroy, OnInit, ViewChild  } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Permissions, User} from '../../data-structure-models/user';

import { ApiService } from '../../services/api.service';
import { HelpersService } from '../../helpers/helpers.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

// data modals
import { DASHBOARDDATA, DASHBOARDDATAEMPTY } from '../../../assets/data/mockEndpointResponses/dashboardsummary';

// Store Imports
import {GetDashboardData, HasAgencyData} from '../../store/actions/api_data.actions';
import { Store, select } from '@ngrx/store';
import {Observable, of, throwError} from 'rxjs';
import {AppState, selectApiState, selectAuthState} from '../../store/app.states';
import {GetUserData, LogIn, LogInFailure, LogInSuccess, LogOut, UpdateUserData} from '../../store/actions/auth.actions';
import {TabsetComponent} from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnDestroy, OnInit {
  private dashboard_summary_url = 'dashboardsummary?format=json';
  public response;
  private authSubscription;
  private apiSubscription;
  private routerSubscription;
  private offlineErrorMsg = '';
  private user: User;
  private profileChanges = false;
  private fNameValue = '';
  private lNameValue = '';
  private emailValue = '';
  private imgSelectedTag = 'img3';
  private imgSelectedUrl = 'https://source.unsplash.com/j5MCxwaP0R0/100x100';
  private imgSavedTag = 'img3';
  private imgSavedUrl = '';
  private showPass1 = false;
  private showPass2 = false;
  private showPass3 = false;
  private oldPass = '';
  private newPass = '';
  private newPass2 = '';
  private passwordErrorMessage = '';
  private pass1Active = false;
  private pass2Active = false;
  private pass3Active = false;
  private pass1Valid = true;
  private pass2Valid = true;
  private pass3Valid = true;
  private pwSuccessMessage = '';
  private userCookie;
  getState: Observable<any>;
  getAuthState: Observable<any>;
  usingLiveData = true;
  public permissions = new Permissions();
  public initialized = false;
  @ViewChild('passwordModal', {static: true}) passwordModalClose;
  @ViewChild('profileTabs', {static: true}) staticTabs: TabsetComponent;

  constructor(private apiService: ApiService,
              private httpClient: HttpClient,
              private router: Router,
              private store: Store<AppState>,
              private helpersService: HelpersService,
              private route: ActivatedRoute) {
    this.response = DASHBOARDDATAEMPTY;
    this.getState = this.store.select(selectApiState);
    this.getAuthState = this.store.select(selectAuthState);
    this.userCookie = helpersService.getUserInfoCookie();

  }

  ngOnInit() {
   this.routerSubscription = this.route.paramMap.subscribe(params => {
      const defualtTab = params.get('id');
      if ( defualtTab && defualtTab.toLowerCase() === 'settings') {
        this.staticTabs.tabs[2].active = true;

      }
    });
   this.authSubscription = this.getAuthState.subscribe((state) => {
      if (state && state.user && state.user.permissions) {
        this.initialized = true;
        this.permissions = state.user.permissions;
        this.user = state.user;
        this.fNameValue = this.user.fName;
        this.lNameValue = this.user.lName;
        this.emailValue = this.user.email;
        this.imgSavedUrl = this.switchImageSize(this.user.avatar, '400x400');
      }
    });
   this.apiSubscription = this.getState.subscribe((state) => {
      // Check if this API call is in Store memory
      if (state.dashboard_api_requests && state.dashboard_api_requests.length > 0) {
        let date;
        let successDate;
        // iterate over each saved response
        for (let i = state.dashboard_api_requests.length - 1; i >= 0; i--) {
          // iterated from most recent respponses and look for matching
          if (state.dashboard_api_requests[i].url === this.dashboard_summary_url) {
            // if most recent request for this call was error, continue looking for valid data to use,
            // but make error note to user (do not break loop)
            if (state.dashboard_api_requests[i].response.Error) {
              if (!date) {
                // set date of most recent error so we know if we should retry req. below
                date = state.dashboard_api_requests[i].date;
              }
              this.usingLiveData = false;
              this.offlineErrorMsg = 'Warning: Page encountered error getting data! Please try again later.';
            } else {
              this.response = state.dashboard_api_requests[i].response;
              successDate = state.dashboard_api_requests[i].date;
              if (this.response && this.response._source && this.response._source.indexOf('cached') !== -1) {
                this.usingLiveData = false;
                this.offlineErrorMsg = 'Warning: Site is using cached data in offline mode, some features may be unavailable!';
              } else {
                if (date) {
                  this.usingLiveData = false;
                  this.offlineErrorMsg = 'Warning: Page is using cached data, API service may be temporarily unavailable. ' +
                    'Please try again later.';
                } else {
                  this.usingLiveData = true;
                }
              }

              const ONE_HOUR = 60000 * 60;
              if (Date.now() - successDate >= ONE_HOUR) {
                console.log('Dashboard fetching more up to date data');
                this.callDashboardApi();
              }
              break;
            }
            const ONE_MINUTE = 60000;
            if (!date || ((Date.now() - date) >= ONE_MINUTE)) {
              console.log('dashboad looking for data');
              this.callDashboardApi();
            }
          }
        }
      } else {
        this.response = DASHBOARDDATA;
        console.log('dashboad looking for data');
        this.callDashboardApi();
      }
    });
  }


  callDashboardApi() {
    // Dashboard API is now called from the ngrx store LogInSuccess effect--
    // This is in attempt to get fastest load time possible
    const payload = {
      url: this.dashboard_summary_url
    };
    this.store.dispatch(new GetDashboardData(payload));
  }

  ngOnDestroy() {
    // this.dashboardSubscription.unsubscribe();
    this.authSubscription.unsubscribe();
    this.apiSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  imageSelected(tag, url) {
    if (this.imgSelectedTag) {
      const prevImg = document.getElementById(this.imgSelectedTag);
      prevImg.classList.remove('itemSelected');
    }
    const curImg = document.getElementById(tag);
    curImg.classList.add('itemSelected');
    this.imgSelectedTag = tag;
    this.imgSelectedUrl = url;
    this.imgSaved();
  }

  imgSaved() {
    this.imgSavedUrl = this.switchImageSize(this.imgSelectedUrl, '400x400');
    this.imgSavedTag = this.imgSelectedTag;
    // UpdateUserData
    const payload = this.user;
    this.user.avatar = this.imgSelectedUrl;
    this.store.dispatch(new UpdateUserData(payload));
    this.helpersService.setUserInfoCookie({avatar: this.user.avatar});
    const cookie = this.helpersService.getUserInfoCookie();
  }

  switchImageSize(imgUrl, newSize) {
    let newImgUrl = imgUrl;
    if (imgUrl && imgUrl.length > 0 && imgUrl.endsWith('100x100')) {
      let reachedXyet = false;
      let isValid = true;
      const lastIndex = imgUrl.lastIndexOf('/');
      const mainURL = imgUrl.substring(0, lastIndex + 1);
      const sizeURL = imgUrl.substring(lastIndex + 1, imgUrl.length);
      for (let i = 0; i < sizeURL.length; i++) {
        const char = sizeURL.charAt(i);
        if (reachedXyet) {
          if (!this.is_numeric(char)) {
            isValid = false;
          }
        }
        if (!reachedXyet) {
          if (!this.is_numeric(char) && char !== 'x') {
            isValid = false;
          } else if (char === 'x') {
            reachedXyet = true;
          }
        }
        if (i === sizeURL.length - 1 && isValid) {
          newImgUrl = mainURL + newSize;
        }
      }
    }
    return newImgUrl;
  }

  is_numeric(str) {
    return /^\d+$/.test(str);
  }

  resetResetPassword() {
    this.showPass1 = false;
    this.showPass2 = false;
    this.showPass3 = false;
    this.oldPass = '';
    this.newPass = '';
    this.newPass2 = '';
    this.pass1Active = false;
    this.pass2Active = false;
    this.pass3Active = false;
    this.passwordErrorMessage = '';
    this.pass1Valid = true;
    this.pass2Valid = true;
    this.pass3Valid = true;
    this.resetError(1);
    this.resetError(2);
    this.resetError(3);
    this.pwSuccessMessage = '';
  }

  changePassword() {
    this.pwSuccessMessage = '';
    console.log('changing password');
    this.onTypeValidation(3, true);
    if (this.pass1Valid && this.pass2Valid && this.pass3Valid) {
      const cpSpin = document.getElementById('cpspin');
      cpSpin.classList.add('spinner-border');
      cpSpin.classList.add('spinner-border-sm');
      this.apiService.logIn(this.user.email, this.oldPass).subscribe(
        data => {
            if (data && data.Error) {
              this.markInvalid(1, 'Invalid Old Password!');
            } else if (data.token && data.token.length > 0) {
              const endpoint = 'change_user_password';
              let payload = {
                user_name: this.user.email,
                new_password: this.newPass
              };
              this.apiService.postData(endpoint, payload).subscribe(result => {
                if (result && result.Result && result.Result.toLowerCase().indexOf('success') !== -1) {
                  console.log('successfully changed password!');
                  console.log(result);
                  this.pwSuccessMessage = 'Successfully Changed Password!';
                  // remove loading icon
                  // $('#search').toggleClass('loading');
                } else if (result && result.Result) {
                  this.passwordErrorMessage = 'Error: Could not save new password. ' + result.Result;
                } else {
                  console.log('Could not change password');
                  console.log(result);
                  this.passwordErrorMessage = 'Error communicating with backend. Could not save new password.';
                }
              });
            } else {
            this.markInvalid(1, 'Error validating Old Password!');
          }
        },
        err => {
          console.error(err);
          setTimeout(() => {
            this.markInvalid(1, 'Error validating Old Password!');
          }, 300);
        },
        () => {
          console.log('done');
          cpSpin.classList.remove('spinner-border');
          cpSpin.classList.remove('spinner-border-sm');
/*          setTimeout(() => {
            oldpass.classList.remove('shakeit');
          }, 300);*/
        }
      );
    }
  }

  onTypeValidation(inputBox, isFinal) {
    // validate first password
    this.passwordErrorMessage = '';
    if (inputBox === 1) {
      this.resetError(1);
      if (this.oldPass.length === 0 && isFinal) {
        this.markInvalid(1, 'Warning: Old Password must not be blank!');
      }
    }
    // validate first, then second password
    if (inputBox === 2) {
      this.onTypeValidation(1, true);
      this.resetError(2);
      if (this.newPass.length === 0 && isFinal) {
        this.markInvalid(2, 'Warning: New Password must not be blank!');
      } else if (isFinal) {
        const passwordValidation = this.checkPwd(this.newPass);
        if (passwordValidation !== 'ok') {
          this.markInvalid(2, passwordValidation);
        }
      }
      if (this.pass2Valid && this.newPass === this.newPass2) {
        this.resetError(3);
      } else if (this.newPass2.length > 0 && this.pass2Valid && this.newPass !== this.newPass2) {
        this.markInvalid(3, 'Warning: New Passwords do not match!');
      }
    }
    // validate first, then second, then third password
    if (inputBox === 3) {
      this.onTypeValidation(1, true);
      this.onTypeValidation(2, true);
      this.resetError(3);
      if (this.newPass2.length > this.newPass.length || (this.newPass2.length > 0 && this.newPass2.length < this.newPass.length && !this.newPass.startsWith(this.newPass2)) || (isFinal && this.newPass !== this.newPass2)) {
        this.markInvalid(3, 'Warning: New Passwords do not match!');
      } else if (this.newPass2.length === 0 && isFinal) {
        this.markInvalid(3, 'Warning: New Password must not be blank!');
      } else if (isFinal) {
        const passwordValidation = this.checkPwd(this.newPass2);
        if (passwordValidation !== 'ok') {
          this.markInvalid(3, passwordValidation);
        }
      }
    }
  }
  markInvalid(type, message) {
      if (type === 1) {
        this.pass1Valid = false;
        const oldpass = document.getElementById('pass1');
        setTimeout(() => {
          oldpass.classList.add('makeRed');
          oldpass.classList.add('shakeit');
        }, 300);
        if (this.passwordErrorMessage.length === 0) {
          this.passwordErrorMessage = message;
        }
      } else if (type === 2) {
        this.pass2Valid = false;
        const pass2 = document.getElementById('pass2');
        setTimeout(() => {
          pass2.classList.add('makeRed');
          pass2.classList.add('shakeit');
        }, 300);
        if (this.passwordErrorMessage.length === 0) {
          this.passwordErrorMessage = message;
        }
      } else if (type === 3) {
        this.pass3Valid = false;
        const pass3 = document.getElementById('pass3');
        setTimeout(() => {
          pass3.classList.add('makeRed');
          pass3.classList.add('shakeit');
        }, 300);
        if (this.passwordErrorMessage.length === 0) {
          this.passwordErrorMessage = message;
        }
      }
  }
  resetError(type) {
    if (type === 1) {
      const pass1 = document.getElementById('pass1');
      pass1.classList.remove('makeRed');
      pass1.classList.remove('shakeit');
      this.pass1Valid = true;
    } else if (type === 2) {
      const pass2 = document.getElementById('pass2');
      pass2.classList.remove('makeRed');
      pass2.classList.remove('shakeit');
      this.pass2Valid = true;
    } else if (type === 3) {
      const pass3 = document.getElementById('pass3');
      pass3.classList.remove('makeRed');
      pass3.classList.remove('shakeit');
      this.pass3Valid = true;
    }
  }
  checkPwd(str) {
    if (str.length < 6) {
      return('Password must be at least 6 characters');
    } else if (str.length > 50) {
      return('Passwaord cannot be greater than 50 characters');
    } else if (str.search(/\d/) === -1) {
      return('Password must contain a number');
    } else if (str.search(/[a-zA-Z]/) === -1) {
      return('Password must contain a letter');
    } else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+]/) !== -1) {
      return('Password contains invalid special characters, accepted special characters are: !@#$%^&*()_+');
    }
    return('ok');
  }
}
