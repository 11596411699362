import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import {map, switchMap, tap, catchError, withLatestFrom} from 'rxjs/operators';
import {
  ApiGetDataType,
  GetDashboardData,
  HasDashboardData,
  GetAgencyData,
  HasAgencyData,
  GetKeywordData,
  HasKeywordData,
  UpdateAppState
} from '../actions/api_data.actions';
import { ApiService } from '../../services/api.service';
import { Store } from '@ngrx/store';
import { AppState, selectApiState } from '../../store/app.states';
// import {AuthActionTypes, LogIn, LogInFailure, LogInSuccess} from "../actions/auth.actions";
// import { DashboardData } from '../../internal/dashboard/dashboard-data';

@Injectable()
export class ApiEffects {

  constructor(
    private actions: Actions,
    private apiService: ApiService,
    private store: Store<AppState>
  ) {
  }

  // effects go here
  @Effect()
  GetDashboardData: Observable<any> = this.actions.pipe(
    ofType(ApiGetDataType.DASHBOARD_SUMMARY),
    map((action: GetDashboardData) => action.payload),
    switchMap(payload => {
      return this.apiService.getData(payload.url).pipe(
        map((response) => {
          return new HasDashboardData({url: payload.url, response});
        })).pipe(
        catchError((error) => {
          // return of(new LogInFailure({ error }));
          return null;
        }));
    }));
  @Effect()
  GetAgencyData: Observable<any> = this.actions.pipe(
    ofType(ApiGetDataType.AGENCY_SUMMARY),
    map((action: GetAgencyData) => action.payload),
    switchMap(payload => {
      return this.apiService.getData(payload.url).pipe(
        map((response) => {
          return new HasAgencyData({url: payload.url, response});
        })).pipe(
        catchError((error) => {
          // return of(new LogInFailure({ error }));
          return null;
        }));
    }));
  @Effect()
  GetKeywordData: Observable<any> = this.actions.pipe(
    ofType(ApiGetDataType.KEYWORD_SEARCH),
    map((action: GetKeywordData) => action.payload),
    switchMap(payload => {
      return this.apiService.getData(payload.url).pipe(
        map((response) => {
          return new HasKeywordData({url: payload.url, response});
        })).pipe(
        catchError((error) => {
          // return of(new LogInFailure({ error }));
          console.log("we are here")
          console.log(error)

          return null;
        }));
    }));
/*  @Effect()
  UpdateAppState: Observable<any> = this.actions.pipe(
    ofType(ApiGetDataType.UPDATE_APP_STATE),
    map((action: UpdateAppState) => action.payload),
    switchMap(payload => {
      return payload;
    }));*/
}
