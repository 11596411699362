import { Component, OnInit, OnDestroy } from '@angular/core';
import {ApiService} from '../../services/api.service';
import { HelpersService } from '../../helpers/helpers.service';

// Store Imports
import {GetAgencyData} from '../../store/actions/api_data.actions';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {AppState, selectApiState, selectAuthState} from '../../store/app.states';
import { FormControl } from '@angular/forms';
import {Permissions} from "../../data-structure-models/user";


@Component({
  selector: 'app-ssr-reports',
  templateUrl: './ssr-reports.component.html',
  styleUrls: ['./ssr-reports.component.css']
  // , '../../../assets/vendor/cgi_dpl/sass/components/_buttons.scss'
})
export class SsrReportsComponent implements OnInit, OnDestroy {
  private agency_summary_url = 'agencysummary?format=json';
  private agencySubscription;
  getState: Observable<any>;
  public awarded_url = 'getAwardedOpps?id=';
  public active_solicitations_url = 'getActiveOpps?issmallbiz=1&id=';
  public active_solicitations_no_small_biz_url = 'getActiveOpps?issmallbiz=0&id=';
  defualt_agency = [{
    id: '',
    value: '',
    label: '',
    govEntityName: 'Select Account',
    govEntityDescription: '',
    LastProcessed: '',
    ssrReady: '',
    keywordSearchReady: '',
    RecordcountRS: '',
    FilesDownloadCount: '',
    Filedownloadratio: '',
    EsIndexratio: '',
    ESemptycount: null,
    ESindexcount: null,
    FileCount: '',
    FilesFailed: null,
    FilesSucceded: null,
    Recordcount: '',
    Reldoccount: null
  }];
  response = this.defualt_agency;
  usingLiveData = true;
  pendingResponse = [];
  agency = {
    id: '1', // this id you'll send and get from backend
    govEntityName: 'Select Account'
  };
  selectedAgency = [this.defualt_agency[0]];
  multipleControl = new FormControl();
  offlineErrorMsg = '';
  hasNoFiles = false;
  disableDownloadButtons = true;

  hasMultiSelect = false;
  public permissions = new Permissions();
  public initialized = false;
  private authSubscription;
  getAuthState: Observable<any>;
  constructor(private apiService: ApiService, private helpersService: HelpersService, private store: Store<AppState>) {
    this.getState = this.store.select(selectApiState);
    this.getAuthState = this.store.select(selectAuthState);
  }

  ngOnInit() {
    this.authSubscription = this.getAuthState.subscribe((state) => {
      if (state && state.user && state.user.permissions) {
        this.initialized = true;
        this.permissions = state.user.permissions;
        if (this.permissions.ssr) {
          this.multipleControl = new FormControl();
          this.selectedAgency = [this.defualt_agency[0]];
          if (document.getElementById('as_spin_button')) {
            (document.getElementById('as_spin_button') as HTMLInputElement).disabled = true;
          }
          if (document.getElementById('ass_spin_button')) {
            (document.getElementById('ass_spin_button') as HTMLInputElement).disabled = true;
          }
          if (document.getElementById('asns_spin_button')) {
            (document.getElementById('asns_spin_button') as HTMLInputElement).disabled = true;
          }
        }
      }
    });
   this.agencySubscription =  this.getState.subscribe((state) => {
      // only Proceed if we are waiting on API data
        if (state.agencysummary_api_requests && state.agencysummary_api_requests.length > 0) {
          let date;
          let successDate;
          // iterate over each saved response
          for (let i = state.agencysummary_api_requests.length - 1; i >= 0; i--) {
            // iterated from most recent respponses and look for matching
            if (state.agencysummary_api_requests[i].url === this.agency_summary_url) {
              // if most recent request for this call was error, continue looking for valid data to use,
              // but make error note to user (do not break loop)
              if (state.agencysummary_api_requests[i].response.Error) {
                if (!date) {
                  // set date of most recent error so we know if we should retry req. below
                  date = state.agencysummary_api_requests[i].date;
                }
                this.usingLiveData = false;
                this.offlineErrorMsg = 'Warning: Page encountered error getting data! Please try again later.';
              } else {
                this.pendingResponse = state.agencysummary_api_requests[i].response;
                successDate = state.agencysummary_api_requests[i].date;
                // if in offline mode
                if (this.pendingResponse && this.pendingResponse[0]['_source'] && this.pendingResponse[0]['_source'].indexOf('cached') !== -1) {
                  this.usingLiveData = false;
                  // console.log('set usingLiveData false');
                  this.offlineErrorMsg = 'Warning: Site is using cached data in offline mode, some features may be unavailable!';
                } else {
                  if (date) {
                    // if last api response for endoint was error
                    this.usingLiveData = false;
                    this.offlineErrorMsg = 'Warning: Page is using cached data, API service may be temporarily unavailable. ' +
                      'Please try again later.';
                  } else {
                    // else good-- live date
                    this.usingLiveData = true;
                  }
                }
                const ONE_HOUR = 60000 * 60;
                if (Date.now() - successDate >= ONE_HOUR) {
                  console.log('SSR fetching more up to date data');
                  this.callAgencyApi();
                }
                this.pendingResponse = this.pendingResponse.filter(item => item.govEntityName.length > 0);
                // sort array by agency name
                this.pendingResponse.sort((a, b) => {
                  var textA = a.govEntityName.toUpperCase();
                  var textB = b.govEntityName.toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                this.pendingResponse.forEach(entry => {
                  entry.label = entry.govEntityName;
                  entry.value = entry.id;
                });
                this.response = this.pendingResponse;
                break;
              }
              const ONE_MINUTE = 60000;
              if (!date || ((Date.now() - date) >= ONE_MINUTE)) {
                console.log('SSR looking for data');
                this.callAgencyApi();
              }
            }
          }
        } else {
          // this.response = this.processBooleans(AGENCYDATA_EMPTY);
          console.log('SSR looking for data');
          this.callAgencyApi();
        }
    });
  }
  callAgencyApi() {
    const payload = {
      url: this.agency_summary_url
    };
    this.store.dispatch(new GetAgencyData(payload));
  }
  selected() {
    // console.log(this.selectedLevel);
    // console.log(this.multipleControl.value);
    this.selectedAgency = [];
    this.hasNoFiles = false;
    if (this.multipleControl.value && this.multipleControl.value[0]) {
      this.multipleControl.value.forEach(agencyID => {
        this.selectedAgency.push(this.response.find(o => o.id === agencyID));
        if (+this.selectedAgency[this.selectedAgency.length - 1].FileCount <= 0) {
          this.hasNoFiles = true;
        }
      });
    } else {
      this.selectedAgency = [this.defualt_agency[0]];
    }
    // error check while API doesnt work
    if (this.selectedAgency[1]) {
      this.hasMultiSelect = true;
    } else {
      this.hasMultiSelect = false;
    }
    let updatedValidation = false;
    this.selectedAgency.forEach(selectedAgency => {
      if (+selectedAgency.FileCount <= 0) {
        this.disableDownloadButtons = true;
        updatedValidation = true;
      }
    });
    if (!updatedValidation) {
      this.disableDownloadButtons = false;
    }
  }

  downloadClicked(btnSelector, spinSelector, url, downloadPrefix) {
    // call selected() again to validate as onChange does not always fire on dropdown deletion
   this.selected();
    let asSpin = document.getElementById(spinSelector);
    // let asDownload = document.getElementById('as_download');
    (document.getElementById(btnSelector) as HTMLInputElement).disabled = true;
      asSpin.classList.remove('fas');
      asSpin.classList.remove('fa-file-download');
      asSpin.classList.add('spinner-border');
      asSpin.classList.add('spinner-border-sm');
    let initFlag = false;
    let name = '';
    let combinedURL = url;
    let singleURL = url + this.selectedAgency[0].id; //switch to combined URL when API works
    // This section is for when download capability of API is extended to combine agencies in single call
    this.selectedAgency.forEach(selectedAgency => {
      if (!initFlag) {
        combinedURL += selectedAgency.id;
        name = selectedAgency.govEntityName + selectedAgency.id;
        initFlag = true;
      } else {
        combinedURL += ',' + selectedAgency.id;
        name += '_' + selectedAgency.govEntityName + selectedAgency.id;
      }
    });
      // let individualURL = url + agencyCall.id;
      // let individualName = agencyCall.govEntityName + agencyCall.id;
      // let fileName = downloadPrefix + '_' + individualName + '_' + this.helpersService.formatDate() + '.xlsx';
      let fileName = downloadPrefix + '_' + name + '_' + this.helpersService.formatDate() + '.xlsx';
    let request = this.apiService.downloadExcelData(singleURL, name);
    let that = this;
    request.onload = function(event) {
        console.log(event);
        let blob = this.response;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // For IE
          window.navigator.msSaveOrOpenBlob(blob, fileName);
          if (!that.disableDownloadButtons && that.selectedAgency[0].id !== '' && that.multipleControl.value && that.multipleControl.value.length >= 0) {
            console.log('enabling');
            (document.getElementById(btnSelector) as HTMLInputElement).disabled = false;
          }
        } else {
          // Not IE
          let downloadLink = window.document.createElement('a');
          let contentTypeHeader = request.getResponseHeader('Content-Type');
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: contentTypeHeader}));
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          if (!that.disableDownloadButtons && that.selectedAgency[0].id !== '' && that.multipleControl.value && that.multipleControl.value.length >= 0) {
            console.log(that.selectedAgency[0].id);
            console.log(that.multipleControl.value);
            console.log('enabling');
            (document.getElementById(btnSelector) as HTMLInputElement).disabled = false;
          }
          asSpin.classList.remove('spinner-border');
          asSpin.classList.remove('spinner-border-sm');
          asSpin.classList.add('fas');
          asSpin.classList.add('fa-file-download');
        }
      };
  }
  ngOnDestroy() {
    this.agencySubscription.unsubscribe();
    this.authSubscription.unsubscribe();
  }
}
