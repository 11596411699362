import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import {SearchResultsDataModel} from '../../data-structure-models/data-models';
import {searchTestData} from '../../data-structure-models/searchTestData';
import { ApiService } from '../../services/api.service';
import { DataTableDirective } from 'angular-datatables';
import {Observable, Subject} from "rxjs";
import { HelpersService } from '../../helpers/helpers.service';
import {Permissions} from "../../data-structure-models/user";
import {AppState, selectAuthState} from "../../store/app.states";
import {Store} from "@ngrx/store";


@Component({
  selector: 'app-keyword-search',
  templateUrl: './keyword-search.component.html',
  styleUrls: ['./keyword-search.component.css']
})

export class KeywordSearchComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  hasSearchResults = false;
  searchResultsData: Array<SearchResultsDataModel>;
  dtTrigger: Subject<SearchResultsDataModel> = new Subject();
  keywordSearchUrl: string;
  searchTerm: string;
  lastSearchedTerm: string;
  errorMessage: string;
  datepickerModel: Date;
  daterangepickerModel: Date[];
  defaultFromDate = new Date();
  defaultToDate = new Date();
  fromDate = new Date();
  toDate = new Date();
  usingLiveData = true;
  offlineErrorMsg = '';
  public permissions = new Permissions();
  public initialized = false;
  private authSubscription;
  getAuthState: Observable<any>;


  constructor(private apiService: ApiService, private helpersService: HelpersService, private store: Store<AppState>) {
    this.lastSearchedTerm = '';
    this.getAuthState = this.store.select(selectAuthState);
  }

ngOnInit() {
  this.authSubscription = this.getAuthState.subscribe((state) => {
    if (state && state.user && state.user.permissions) {
      this.initialized = true;
      this.permissions = state.user.permissions;
    }
  });
  this.fromDate.setDate(1);
  this.keywordSearchUrl = 'getKeywordSearchCombined?format=json&key=';
  this.searchTerm = '';
  this.lastSearchedTerm = '';
  this.dtOptions = {
    pagingType: 'simple_numbers',
    pageLength: 10,
    ordering: true,
    searching: true,
    language: {
      search: 'Filter:'
    }
  };
}

  onDownload(searchValue) {
    // only continue if user has current search values entered
    if (searchValue && searchValue.length > 0) {
      // get data from api if we have not already searched for term
      if (!this.hasSearchResults && this.lastSearchedTerm !== searchValue) {
        $('#search').toggleClass('loading');
        this.onClear();
        let thisSearch = this.keywordSearchUrl + searchValue;
        // wait for data via api request
        this.apiService.getData(thisSearch).subscribe(searchResultsData => {
          this.searchResultsData = searchResultsData;
          if (searchResultsData && searchResultsData.length > 0) {
            this.hasSearchResults = true;
            // remove loading icon
            $('#search').toggleClass('loading');
            this.helpersService.downloadCsv(this.searchResultsData, 'searchResults.csv');
          }
        });
      } else {
        // else we already have api data from previous search
        this.helpersService.downloadCsv(this.searchResultsData, 'searchResults.csv');
      }
    }
  }

  onSearch(searchValue) {
    // start loading icon
    if (searchValue && searchValue.length > 0) {
      $('#search').toggleClass('loading');
      this.onClear();
      let thisSearch = this.keywordSearchUrl + searchValue;
      // wait for data via api request
      this.apiService.getData(thisSearch).subscribe(searchResultsData => {
        if (searchResultsData && searchResultsData.length > 0 && !searchResultsData.Error) {
          this.lastSearchedTerm = searchValue;
          this.searchResultsData = searchResultsData;
          this.hasSearchResults = true;
          this.usingLiveData = true;
          this.offlineErrorMsg = '';
          this.errorMessage = '';

          // remove loading icon
        } else if (searchResultsData.Error) {
          this.usingLiveData = false;
          this.offlineErrorMsg = 'Warning: Page encountered error getting data! Please try again later.';
        } else {
          this.errorMessage = 'No Results Found!';
        }
        console.log(searchResultsData);
        $('#search').toggleClass('loading');
      });
    }
  }

  onClear() {
    this.dtTrigger.next();
    this.searchResultsData = [];
    this.hasSearchResults = false;
    this.lastSearchedTerm = '';
    this.errorMessage = '';
    this.usingLiveData = true;
    this.offlineErrorMsg = '';
  }

  keyupFunction(event, searchValue) {
    this.searchTerm = searchValue;
    $('form').submit(function() { return false; });
    if (event.keyCode === 13) {
      this.onSearch(searchValue);
      // rest of your code
    }
  }
  setTestData() {
  $('#search').toggleClass('loading');
  setTimeout(() => {
      this.searchResultsData = searchTestData;
      console.log(this.searchResultsData);
      this.hasSearchResults = true;
      $('#search').toggleClass('loading');
    }, 1500);
}
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    this.authSubscription.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  getText(text) {
    let textArray = text.split('.');
    let adjustedTextArray = [];
    if (textArray.length > 1) {
      let counter = 0;
      for (let i = 0; i <  textArray.length; i++) {
        console.log(textArray[i]);
        if (textArray[i + 1] && textArray[i + 1].length > 0 && (textArray[i + 1][0] === ' ' || textArray[i + 1][0] === textArray[i + 1][0].toUpperCase())) {

        } else {
          if (textArray[i + 1]) {
            adjustedTextArray.push(textArray[i] + textArray[i + 1]);
          } else {
            adjustedTextArray.push(textArray[i]);
          }
        }
      }
    } else {
      return '+++++' + text;
    }
    return '+++++' + text;
  }
}