import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../../../assets/css/sb-admin-2.css']
})

export class HomeComponent implements OnInit {

  constructor(
  			private apiService: ApiService,
			private cookieService: CookieService
			) { }

	logout(){
		/*this.apiService.logout();*/
	}

  ngOnInit() { }

}
