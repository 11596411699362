import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { User } from '../data-structure-models/user';
import {LogOut} from "../store/actions/auth.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../store/app.states";
import {UpdateAppState, UpdateErrorLog} from "../store/actions/api_data.actions";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
	//API_URL: string = "../assets/data/";
	private API_URL: string = 'https://api.etpflash.com/api/';
	private full_url:string;

	constructor(private httpClient: HttpClient,
				private cookieService: CookieService,
        private store: Store<AppState>,
				private router: Router
				) { }

  getToken(): string {
    // return localStorage.getItem('token');
    let cookie = this.cookieService.get('etpflashtoken');
    if (!cookie) {
      console.log('invalid cookie, logging out');
      this.store.dispatch(new LogOut());
    }
    return cookie;
  }

	getData(api_endpoint:string) {
	  console.log('...Retrieving... ');
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
			})
		};
		this.full_url = this.API_URL + api_endpoint;
		let response = this.httpClient.get<any>(this.full_url, httpOptions);
		return response;
	}
  postData(api_endpoint:string, payload?: object) {
    console.log('...Retrieving... ');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    this.full_url = this.API_URL + api_endpoint;
    let response;
    if (payload) {
      response = this.httpClient.post<any>(this.full_url, payload, httpOptions);
    } else {
      response = this.httpClient.post<any>(this.full_url, httpOptions);
    }
    return response;
  }

	// TODO convert to httpClient to utilize tokenInterceptor
  downloadExcelData(api_endpoint:string, fileName) {
    let url = this.API_URL + api_endpoint;
    let request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.setRequestHeader('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;');
    request.setRequestHeader('Authorization', 'Token ' + this.getToken());
    request.responseType = 'blob';
    request.send();
    return request;
  }

  logIn(username: string, password: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.httpClient.post<User>('https://api.etpflash.com/api-token-auth/',  {password, username}, httpOptions);
  }

  // future enhancement
  signUp(email: string, password: string): Observable<User> {
    return null;
  }

  logError(dateObject, issueComment, loc) {
	  let payload = {
	    date: dateObject,
      issue: issueComment,
      location: loc
    };
   this.store.dispatch(new UpdateErrorLog(payload));
  }
}
