export class User {
  id?: string;
  email?: string;
  password?: string;
  token?: string;
  avatar?: string;
  subscriptions?: [];
  userLevel?: number;
  permissions?: Permissions;
  fName?: string;
  lName?: string;
}
export class Permissions {
    dashboard: boolean;
    user: boolean;
    ssr: boolean;
    search: boolean;
    agencySummary: boolean;
    admin: boolean;
    showButtons: boolean;
}
