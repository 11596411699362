import { Component, OnInit, OnDestroy } from '@angular/core';
import {ApiService} from '../../services/api.service';
import { HelpersService } from '../../helpers/helpers.service';

// Store Imports
import {GetAgencyData, GetDashboardData} from '../../store/actions/api_data.actions';
import { Store, select } from '@ngrx/store';
import {from, Observable} from 'rxjs';
import {AppState, selectApiState, selectAuthState} from '../../store/app.states';
import { FormControl } from '@angular/forms';
import {SignUp, UpdateUserData} from '../../store/actions/auth.actions';
import { UpdateAppState } from '../../store/actions/api_data.actions';
import { AppSettings } from '../../data-structure-models/appSettings';
import {Permissions} from "../../data-structure-models/user";

@Component({
  selector: 'app-diagnostic-panel',
  templateUrl: './diagnostic-panel.component.html',
  styleUrls: ['./diagnostic-panel.component.css']
  // , '../../../assets/vendor/cgi_dpl/sass/components/_buttons.scss'
})
export class DiagnosticPanelComponent implements OnInit, OnDestroy {
  // api/getErrorReportDetailed?start_date=<Y-m-d>&end_date=<Y-m-d>&ids=<agency ids that are comma delimited>&top_n=<whole number greater than zero>
  private agency_summary_url = 'agencysummary?format=json';
  private error_report_url = 'getErrorReportDetailed?format=json';
  private agencySubscription;
  private authSubscription;
  getState: Observable<any>;
  getAuthState: Observable<any>;
  numOfErrors = '5';
  isLiveData = true;
  public awarded_url = 'getAwardedOpps?id=';
  public active_solicitations_url = 'getActiveOpps?issmallbiz=1&id=';
  public active_solicitations_no_small_biz_url = 'getActiveOpps?issmallbiz=0&id=';
  startDate = new Date();
  endDate = new Date();
  daterangepickerModel: Date[];
  searchedError = {};
  userJson = {};
  isJSONExpanded = false;
  isExpanded = false;
  apiQuery = 'getKeywordSearchCombined?format=json&key=Test&id=25499';
  defualt_agency = [{
    id: '-1',
    value: '',
    label: '',
    govEntityName: 'Select Account',
    govEntityDescription: '',
    LastProcessed: '',
    ssrReady: '',
    keywordSearchReady: '',
    RecordcountRS: '',
    FilesDownloadCount: '',
    Filedownloadratio: '',
    EsIndexratio: '',
    ESemptycount: null,
    ESindexcount: null,
    FileCount: '',
    FilesFailed: null,
    FilesSucceded: null,
    Recordcount: '',
    Reldoccount: null
  }];
  response = this.defualt_agency;
  pendingResponse = [];
  agency = {
    id: '1', // this id you'll send and get from backend
    govEntityName: 'Select Account'
  };
  selectedAgency = [this.defualt_agency[0]];
  solicitationsApiData: Array<object>;
  // control = new FormControl();
  multipleControl = new FormControl();
  // multipleGroupControl = new FormControl();
  // controText = new FormControl();

  options = [{
    value: 'chrome',
    label: 'Chrome',
    customProperties: {
      icon: 'chrome'
    }
  },
    {
      value: 'explorer',
      label: 'Explorer',
      customProperties: {
        icon: 'internet-explorer'
      }
    }];
  formDefaultState = {
    isLiveData: Boolean
  };
  settingsChanged = false;

  apiDropOptions = [
    {value: 'GET'},
    {value: 'POST'}
  ];
  permissions: Permissions;
  permissionsToSave: Permissions;
  newPermissions;
  public initialized = false;
  apiTypeDropdown = this.apiDropOptions[0].value;
  constructor(private apiService: ApiService, private helpersService: HelpersService, private store: Store<AppState>) {
    this.getState = this.store.select(selectApiState);
    this.getAuthState = this.store.select(selectAuthState);

  }

  ngOnInit() {
    this.startDate.setDate(1);
    this.authSubscription = this.getAuthState.subscribe((state) => {
      if (state && state.user && state.user.permissions) {
        this.initialized = true;
        this.permissions = state.user.permissions;
        this.newPermissions = JSON.stringify(state.user.permissions,null,'\t');
      }
    });
    this.agencySubscription = this.getState.subscribe((state) => {
      // only Proceed if we are waiting on API data
      this.isLiveData = state.appSettings.isLiveData;
      this.formDefaultState.isLiveData = state.appSettings.isLiveData;
      this.validateSettings();
      if (this.response.length === 1) {
        if (state.agencysummary_api_requests && state.agencysummary_api_requests[0]) {
            this.pendingResponse = state.agencysummary_api_requests.find(x => x.url === this.agency_summary_url).response;
          }
        if (!this.pendingResponse || this.pendingResponse.length === 0) {
          this.callAgencyApi();
        } else {
          this.pendingResponse = this.pendingResponse.filter(item => item.govEntityName && item.govEntityName.length > 0);
          // sort array by agency name
          this.pendingResponse.sort((a, b) => {
            var textA = a.govEntityName.toUpperCase();
            var textB = b.govEntityName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          this.pendingResponse.forEach(entry => {
            entry.label = entry.govEntityName;
            entry.value = entry.id;
          });
          this.response = this.pendingResponse;

        }
      }
    });
  }
  callAgencyApi() {
    const payload = {
      url: this.agency_summary_url
    };
    this.store.dispatch(new GetAgencyData(payload));
  }
  selected() {
    // console.log(this.selectedLevel);
    this.selectedAgency = [];
    if (this.multipleControl.value && this.multipleControl.value[0]) {
      this.multipleControl.value.forEach(agencyID => {
        // console.log(this.selectedAgency);
        this.selectedAgency.push(this.response.find(o => o.id === agencyID));
      });
    } else {
      this.selectedAgency = [this.defualt_agency[0]];
    }
  }
  onSearch() {
    this.selected();
    let url = '';
    // let fromDate = '' + this.startDate.getMonth() + '' + this.startDate.getDate() + '' + this.startDate.getFullYear();
    // let toDate = '' + (+this.endDate.getMonth() + 1) + '' + this.endDate.getDate().toString() + '' + this.endDate.getFullYear().toString();
    let fromDay = this.startDate.getDate().toString();
    let fromMonth = (+this.startDate.getMonth() + 1).toString();
    let fromYear = this.startDate.getFullYear().toString();
    let toDay = this.endDate.getDate().toString();
    let toMonth = (+this.endDate.getMonth() + 1).toString();
    let toYear = this.endDate.getFullYear().toString();
    if (fromDay.length === 1) {
      fromDay = '0' + fromDay;
    }
    if (fromMonth.length === 1) {
      fromMonth = '0' + fromMonth;
    }
    if (toDay.length === 1) {
      toDay = '0' + toDay;
    }
    if (toMonth.length === 1) {
      toMonth = '0' + toMonth;
    }
    let fromDate = fromMonth + fromDay + fromYear;
    let toDate = toMonth + toDay + toYear;

    console.log('from date: ' + fromDate);
    console.log('to date: ' + toDate);
    // api/getErrorReportDetailed?start_date=<Y-m-d>&end_date=<Y-m-d>&ids=<agency ids that are comma delimited>&top_n=<whole number greater than zero>
    let selectedIds = '';
    if (this.selectedAgency.length > 0) {
        this.selectedAgency.forEach(id => {
          if (+id.id >= 0) {
            if (!selectedIds) {
              selectedIds = id.id.toString();
            } else {
              selectedIds = selectedIds + ',' + id.id.toString();
            }
          }
      });
    }
    url = this.error_report_url + '&start_date=' + fromDate + '&end_date=' + toDate + '&ids=' + selectedIds + '&top_n=' + this.numOfErrors;
    this.apiService.getData(url).subscribe(errorResults => {
      if (errorResults) {
        this.searchedError = errorResults;
        // remove loading icon
       // $('#search').toggleClass('loading');
      } else {
        console.log('PROBLEM');
      }
    });
  }
  onAPISearch() {
    console.log(this.apiQuery);
    this.userJson = {};
    if (this.apiTypeDropdown === 'GET') {
      this.apiService.getData(this.apiQuery).subscribe(result => {
        if (result) {
          this.userJson = result;
          console.log(this.userJson);
          // remove loading icon
          // $('#search').toggleClass('loading');
        } else {
          console.log('PROBLEM');
        }
      });
    } else if (this.apiTypeDropdown === 'POST') {
      this.apiService.postData(this.apiQuery).subscribe(result => {
        if (result) {
          this.userJson = result;
          console.log(this.userJson);
          // remove loading icon
          // $('#search').toggleClass('loading');
        } else {
          console.log('PROBLEM');
        }
      });
    }
  }
  onSave() {
    console.log(this.isLiveData);
    let payload = new AppSettings();
    payload.isLiveData = this.isLiveData;
    // console.log('payload is: ' + payload);
    this.store.dispatch(new UpdateAppState(payload));
  }
  validateSettings() {
    if (this.isLiveData === !this.formDefaultState.isLiveData) {
      this.settingsChanged = true;
    } else {
      this.settingsChanged = false;
    }
  }
  onCallAPI(endpoint) {
  const agency_summary_url = 'agencysummary?format=json';
  const dashboard_summary_url = 'dashboardsummary?format=json';
  if (agency_summary_url.indexOf(endpoint) !== -1) {
    const payload = {
      url: agency_summary_url
    };
    this.store.dispatch(new GetAgencyData(payload));
  }
  if (dashboard_summary_url.indexOf(endpoint) !== -1) {
      const payload = {
        url: dashboard_summary_url
      };
      this.store.dispatch(new GetDashboardData(payload));
    }
  }
  clearAPISearch() {
    this.userJson = {};
  }
  ngOnDestroy() {
    this.agencySubscription.unsubscribe();
    this.authSubscription.unsubscribe();
  }
  onSavePerm() {
    try {
      let newPerm = JSON.parse(this.newPermissions);
      let permToSave = new Permissions();
      permToSave.ssr = newPerm.ssr;
      permToSave.dashboard = newPerm.dashboard;
      permToSave.user = newPerm.user;
      permToSave.search = newPerm.search;
      permToSave.agencySummary = newPerm.agencySummary;
      permToSave.admin = newPerm.admin;
      permToSave.showButtons = newPerm.showButtons;
      console.log('completed copy success');
      console.log(permToSave);
      let result = {result: {permissions: permToSave}};
      this.store.dispatch(new UpdateUserData(result));
    } catch (err) {
      console.log('completed copy failed');
      console.log(err);
    }
/*    permToSave = JSON.parse(this.newPermissions);
      if (permToSave instanceof Permissions) {
        console.log('permissions is type of!!!');
      } else {
        console.log('not instance of:');
        console.log(permToSave);
        console.log(this.permissions);
      }*/
  }
}
