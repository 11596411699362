import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['../../../assets/css/sb-admin-2.css', './footer.component.css']
})
export class FooterComponent implements OnInit {
  currentYear: number;
  constructor() { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }
}
