import { User } from '../../data-structure-models/user';
import { AuthActionTypes, All } from '../actions/auth.actions';
import {act} from "@ngrx/effects";


export interface State {
  // is a user authenticated?
  isAuthenticated: boolean;
  // if authenticated, there should be a user object
  user: User | null;
  // login error message
  errorMessage: string | null;
}
export const initialState: State = {
  isAuthenticated: false,
  user: null,
  errorMessage: null
};
export function reducer(state = initialState, action: All): State {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        user: {
          token: action.payload.token,
          email: action.payload.email,
          userLevel: action.payload.userGroup
        },
        errorMessage: null
      };
    }
    case AuthActionTypes.FOUND_CREDENTIALS: {
      return {
        ...state,
        isAuthenticated: true,
        user: {
          token: action.payload.token,
          email: action.payload.email,
          userLevel: action.payload.userGroup
        },
        errorMessage: null
      };
    }
    case AuthActionTypes.NOT_FOUND_CREDENTIALS: {
      return {
        ...state
      };
    }
    case AuthActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        errorMessage: 'Incorrect email and/or password.'
      };
    }
    case AuthActionTypes.SIGNUP_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        user: {
          token: action.payload.token,
          email: action.payload.email
        },
        errorMessage: null
      };
    }
    case AuthActionTypes.SIGNUP_FAILURE: {
      return {
        ...state,
        errorMessage: 'That email is already in use.',
      };
    }
    case AuthActionTypes.LOGOUT: {
      return initialState;
    }
    case AuthActionTypes.SET_USER_DATA: {
      console.log("Debug 1");
      console.log(action.payload);
      if (action.payload.result && action.payload.result.email) {
        state.user.email = action.payload.result.email;
      }
      if (action.payload.result && action.payload.result.first_name) {
        state.user.fName = action.payload.result.first_name;
      }
      if (action.payload.result && action.payload.result.last_name) {
        state.user.lName = action.payload.result.last_name;
      }
      if (action.payload.result && action.payload.result.username) {
        state.user.id = action.payload.result.username;
      }
      if (action.payload.result && action.payload.result.avatar) {
        state.user.avatar = action.payload.result.avatar;
      }
      if (action.payload.result && action.payload.result.permissions) {
        state.user.permissions = action.payload.result.permissions;
      } else {
        console.log("Debug 2");
        console.log(action.payload);
        console.log('no permissions: ');
        console.log(action);
      }

      return {
        ...state,
      };
    }
    case AuthActionTypes.GET_USER_PERMISSIONS: {
      return {
        ...state,
      };
    }
    case AuthActionTypes.GET_USER_DATA: {
     //  console.log('setting USER!!!');
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
}
